import {
  AppBar,
  Box,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  AccountCircle,
  Logout,
  Person,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { useLogout } from "../hooks/auth";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import { useAppSelector } from "../store/store";
import { selectAuth } from "../store/auth";
import { selectGlobalIsLoading } from "../store/loading";
import { useNavigate } from "react-router-dom";
import { GlobalSearch } from "./GlobalSearch";
import balloon from "../assets/pballoon-white.svg";

type NavbarParams = {
  onOpenSidebar: () => void;
};

export const Navbar = (params: NavbarParams) => {
  const profilePopupState = usePopupState({
    variant: "popover",
    popupId: "profilePopup",
  });

  const auth = useAppSelector(selectAuth);
  const loading = useAppSelector(selectGlobalIsLoading);
  const { onLogout } = useLogout();

  const navigate = useNavigate();
  const handleClickProfile = () => {
    navigate(`/user/${auth.userKey}/profile`);
  };

  const handleOpenSidebar = () => {
    if (params.onOpenSidebar) params.onOpenSidebar();
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleOpenSidebar}
          edge="start"
          sx={{
            marginRight: 3,
            // ...(open &&  display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          <Box p={1}>
            <img
              src={balloon}
              // src="/src/assets/logo-dark.svg"
              // width={72}
              height={30}
              style={{ paddingTop: "6px" }}
            />
          </Box>
        </Typography>
        <Box mr={3} />
        <GlobalSearch />
        <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }} />
        {auth && (
          <Box>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              color="inherit"
              {...bindTrigger(profilePopupState)}
            >
              <AccountCircle />
            </IconButton>
            <Menu {...bindMenu(profilePopupState)}>
              <MenuItem>
                <ListItemText>
                  <strong>
                    {auth.firstName} {auth.lastName}
                  </strong>
                </ListItemText>
              </MenuItem>
              {/*<MenuItem onClick={handleClickProfile}>*/}
              {/*  <ListItemIcon>*/}
              {/*    <Person />*/}
              {/*  </ListItemIcon>*/}
              {/*  <ListItemText>Profile</ListItemText>*/}
              {/*</MenuItem>*/}
              <MenuItem onClick={onLogout}>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
      {loading && <LinearProgress />}
    </AppBar>
  );
};

import { useHasPermissions } from "../../hooks/auth";
import { useSnackbar } from "notistack";
import { Permission } from "../../logic/auth";

type RequirePermissionsProps = {
  permissions: Permission[];
  children: JSX.Element;
};

export const RequirePermissions = ({
  permissions,
  children,
}: RequirePermissionsProps) => {
  const [hasPermissions] = useHasPermissions();
  const { enqueueSnackbar } = useSnackbar();
  if (hasPermissions(...permissions)) {
    return children;
  } else {
    enqueueSnackbar("You do not have permissions to access this resource", {
      variant: "error",
    });
  }
};

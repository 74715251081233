import { CategoricalDistribution } from "../CategoricalDistribution";

export const InstallReportingDevicesCategoricalDistribution = ({
  reporting,
  notReporting,
}) => {
  return (
    <CategoricalDistribution
      categories={[
        { name: "Reporting", color: "success", value: reporting },
        { name: "Not reporting", color: "error", value: notReporting },
      ]}
    />
  );
};

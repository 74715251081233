import { Typography, useTheme } from "@mui/material";
import { InvoiceStatus } from "../../api/types/billing";
import { useCallback } from "react";

type InvoiceStatusLabelParams = {
  status: InvoiceStatus;
  labelizePaid?: boolean;
};

export const InvoiceStatusLabel = ({
  status,
  labelizePaid,
}: InvoiceStatusLabelParams) => {
  const theme = useTheme();

  const sharedStyle = {
    padding: "1px 10px",
    borderRadius: 1,
  };

  const unpaidStyle = {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    ...sharedStyle,
  };

  const paidStyle = {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    padding: "1px 10px",
    ...sharedStyle,
  };

  const getStyle = useCallback(() => {
    if (status == InvoiceStatus.Open) {
      return unpaidStyle;
    } else if (labelizePaid) {
      return paidStyle;
    } else {
      return {};
    }
  }, [status]);

  return (
    <Typography variant="body2" sx={getStyle()}>
      {InvoiceStatus.toDisplayName(status)}
    </Typography>
  );
};

import { usePaginatedQuery } from "../hooks/paginate";
import { gqlQuery, Paginated } from "./base";
import { gql } from "@apollo/client";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import sjcl from "sjcl";

export interface JobStatuses {
  sent: number;
  received: number;
  running: number;
  completed: number;
  failed: number;
}

export interface RegressionTest {
  id: string;
  models: string[];
  itemKeys: string[];
  deviceKeys: string[];
  jobStatuses: JobStatuses;
  createdDate: string;
  modifiedDate: string;
  createdBy: {
    name: string;
    userKey: string;
  };
}

export const usePaginatedRegressionTests = () => {
  return usePaginatedQuery<RegressionTest>(
    ["regressionTests"],
    async (pager) => {
      return await gqlQuery<{ regressionTestList: Array<RegressionTest> }>(
        gql`
          query regressionTestList(
            $limit: Int
            $page: Int
            $filter: Filter
            $sort: [Sort]
          ) {
            regressionTestList(
              limit: $limit
              page: $page
              filter: $filter
              sort: $sort
            ) {
              id
              name
              createdDate
              modifiedDate
              models
              itemKeys
              deviceKeys
            }
          }
        `,
        {
          page: pager.page,
          limit: pager.limit,
          sort: pager.sort,
          filter: {
            items: pager.filter?.items,
            linkOperator: pager.filter?.linkOperator,
          },
        }
      ).then((res: { regressionTestList: RegressionTest[] }) => {
        return new Paginated({ rows: res?.regressionTestList ?? [] });
      });
    }
  );
};

export const useCreateRegressionTest = (onSuccess?: () => void) => {
  type MutationParams = {
    itemKeys: string[];
    deviceKeys: string[];
    sampleSize: number;
    dgi: string;
    allowStale: boolean;
    testName: string;
    logLevel: string;
    collectTestbox: boolean;
  };
  const client = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (variables: MutationParams) =>
      gqlQuery(
        gql`
          mutation CreateRegressionTest(
            $itemKeys: [ObjectId]!
            $sampleSize: Int!
            $dgi: String!
            $testName: String!
            $allowStale: Boolean!
            $collectTestbox: Boolean!
            $deviceKeys: [ObjectId]
            $logLevel: String
          ) {
            createRegressionTest(
              itemKeys: $itemKeys
              sampleSize: $sampleSize
              dgi: $dgi
              testName: $testName
              allowStale: $allowStale
              deviceKeys: $deviceKeys
              logLevel: $logLevel
              collectTestbox: $collectTestbox
            ) {
              id
              jobStatuses {
                sent
                received
                running
                failed
                completed
              }
            }
          }
        `,
        variables
      ),
    {
      onSuccess: async (res: any) => {
        await client.invalidateQueries("regressionTests");
        const statuses = res.createRegressionTest.jobStatuses;
        enqueueSnackbar(
          `Started ${statuses.sent + statuses.received} regression test(s)`
        );
        if (onSuccess) onSuccess();
      },
    }
  );
};

export const useDeleteRegressionTest = () => {
  type MutationParams = {
    regressionTestKey: string;
    onSuccess?: () => void;
  };
  const client = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (variables: MutationParams) =>
      gqlQuery(
        gql`
          mutation DeleteRegressionTest($regressionTestKey: ObjectId!) {
            deleteRegressionTest(regressionTestKey: $regressionTestKey)
          }
        `,
        variables
      ),
    {
      onSuccess: async (res: any, variables) => {
        await client.invalidateQueries("regressionTests");
        enqueueSnackbar(`Successfully deleted regression test`);
        if (variables.onSuccess) variables.onSuccess();
      },
    }
  );
};

export const useGetRegressionTest = (
  regressionTestKey: string,
  keySuffix: string = null
) => {
  let key = ["regressionTest", regressionTestKey];
  if (keySuffix) key.push(keySuffix);
  return useQuery(key, () =>
    gqlQuery(
      gql`
        query getRegressionTest($regressionTestKey: ObjectId!) {
          regressionTest(regressionTestKey: $regressionTestKey) {
            id
            models
            jobStatuses {
              sent
              received
              running
              failed
              completed
            }
            createdBy {
              userKey
              name
              email
            }
            createdDate
          }
        }
      `,
      {
        regressionTestKey,
      }
    ).then((res) => (<any>res).regressionTest)
  );
};

export const useGetJob = (jobKey: string) => {
  return useQuery(["job", jobKey, "status"], () =>
    gqlQuery<any>(
      gql`
        query getJobStatus($jobKey: ObjectId!) {
          getJob(jobKey: $jobKey) {
            id
            status
            createdDate
            modifiedDate
            name
            description
          }
        }
      `,
      { jobKey }
    ).then((res) => res.job)
  );
};

export const authRemoteTechnician = (deviceKey: string, jobKey: string) => {
  const secret: Buffer = sjcl.random.randomWords(8);
  const clientSecret: string = sjcl.codec.hex.fromBits(
    sjcl.hash.sha256.hash(secret)
  );
  return gqlQuery(
    gql`
      mutation auth(
        $deviceKey: ObjectId!
        $jobKey: ObjectId!
        $clientSecret: String!
      ) {
        authRemoteTechnician(
          deviceKey: $deviceKey
          jobKey: $jobKey
          clientSecret: $clientSecret
        )
      }
    `,
    { deviceKey, jobKey, clientSecret }
  ).then((res: any) => [res.authRemoteTechnician, secret]);
};

export const startRemoteTechnician = (deviceKey: string) => {
  return gqlQuery(
    gql`
      mutation start($deviceKey: ObjectId!) {
        jobKey: startRemoteTechnician(deviceKey: $deviceKey)
      }
    `,
    { deviceKey }
  ).then((res: any) => res.jobKey);
};

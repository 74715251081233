import { Result, tableCellStyles } from "./RegressionTestDetailsResultsTab";
import { useEffect, useMemo, useState } from "react";
import { GridCellParams, GridColumns } from "@mui/x-data-grid-pro";
import { StyledDataGrid } from "../tables/StyledDataGrid";
import clsx from "clsx";
import { useTheme } from "@mui/material";
import { compareResults } from "./helpers";

type RegressionTestResultDiffTable = {
  result: Result;
};

export const RegressionTestResultDiffTable = ({
  result,
}: RegressionTestResultDiffTable) => {
  const theme = useTheme();
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(compareResults(result));
  }, [result]);

  const columns = useMemo(
    (): GridColumns => [
      {
        field: "path",
        headerName: "Path",
        flex: 1,
        renderCell: ({ value }) => <pre>{value}</pre>,
      },
      {
        field: "control",
        headerName: "Current",
        flex: 1,
        cellClassName: (params: GridCellParams<number>) =>
          clsx("cell", {
            modified: result.valuesChanged.includes(params.row.path),
            removed: result.valuesRemoved.includes(params.row.path),
          }),
      },
      {
        field: "variable",
        headerName: "New",
        flex: 1,
        cellClassName: (params: GridCellParams<number>) =>
          clsx("cell", {
            added: result.valuesAdded.includes(params.row.path),
            modified: result.valuesChanged.includes(params.row.path),
          }),
      },
    ],
    [result]
  );
  return (
    <StyledDataGrid
      sx={{
        ...tableCellStyles(theme),
        height: "100%",
      }}
      columns={columns}
      rows={data ?? []}
      getRowId={(row) => row.path}
    />
  );
};

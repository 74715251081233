import { useAppSelector } from "../store/store";
import { selectCurrentEntity } from "../store/entity";
import { useSetCurrentEntity } from "../hooks/entity";
import {
  Breadcrumbs,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "./EntityBreadcrumb.scss";

/**
 * Prints the current entity's ancestors including the current entity in a
 * breadcrumb structure. Users can click any component of the breadcrumb to
 * change the currently selected entity.
 * @constructor
 */
export const EntityBreadcrumb = () => {
  const currentEntity = useAppSelector(selectCurrentEntity);
  const theme = useTheme();
  const shouldCollapse = useMediaQuery(theme.breakpoints.down("md"));
  if (currentEntity == null) return;
  return (
    <div className="flex flex-row">
      <Breadcrumbs
        component="div"
        separator="›"
        maxItems={shouldCollapse ? 1 : 10}
      >
        {currentEntity.hierarchy().map((entity) => (
          <EntityBreadcrumbLink key={entity.entityKey} entity={entity} />
        ))}
      </Breadcrumbs>
    </div>
  );
};

/**
 * Returns a link to one entity in the breadcrumb. When clicked, it selects
 * the entity and makes it the new 'current entity'.
 * @param entity
 * @constructor
 */
const EntityBreadcrumbLink = ({ entity }) => {
  const setCurrentEntity = useSetCurrentEntity();
  const handleClickEntity = async (entityKey) => {
    await setCurrentEntity(entityKey);
  };
  return (
    <Link
      className="breadcrumb-link"
      underline="hover"
      color="inherit"
      onClick={async () => await handleClickEntity(entity.entityKey)}
    >
      {entity.name}
    </Link>
  );
};

import { CategoricalDistribution } from "./CategoricalDistribution";
import { JobStatuses } from "../api/developer";
import { useTheme } from "@mui/material";

export const JobStatusCategoricalDistribution = (props: JobStatuses) => {
  const theme = useTheme();
  return (
    <CategoricalDistribution
      categories={[
        { name: "Sent", value: props.sent, color: theme.jobStatus.sent },
        {
          name: "Received",
          value: props.received,
          color: theme.jobStatus.received,
        },
        {
          name: "Running",
          value: props.running,
          color: theme.jobStatus.running,
        },
        {
          name: "Completed",
          value: props.completed,
          color: theme.jobStatus.completed,
        },
        {
          name: "Failed",
          value: props.failed,
          color: theme.jobStatus.failed,
        },
      ]}
    />
  );
};

import { useQuery, useQueryClient } from "react-query";
import { check, login as callLogin, LoginParams } from "../api";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import Cookies from "js-cookie";
import { store, useAppDispatch, useAppSelector } from "../store/store";
import { logout, login, selectAuth } from "../store/auth";
import {
  selectCurrentEntity,
  setCurrentEntity,
  setDefaultEntity,
  unsetEntityData,
} from "../store/entity";
import { getEntityWithAncestors } from "../api/entity";
import {
  parsePermissions,
  Permission,
  requiresPermissions,
} from "../logic/auth";
import { getUserDetails } from "../api/user";

const AuthStatePollingIntervalMs = 1000;

/**
 * Starts an auth state sync polling timer. For each response, we dispatch the
 * appropriate action to the redux store.
 */
export const useAuthStateSync = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [polling, setPolling] = useState(false);
  const { data, error } = useQuery("auth", check, {
    refetchInterval: polling ? AuthStatePollingIntervalMs : 0,
    enabled: polling,
  });
  useEffect(() => {
    setTimeout(() => setPolling(true), 1000);
  }, []);
  useEffect(() => {
    if (error || !data) return;
    // @ts-ignore
    if (data) {
      if (!data?.authenticated) {
        setPolling(false);
        dispatch(logout());
        navigate("/auth/login");
      }
    }
  }, [data]);
};

/**
 * OnLoginFunction is a function that accepts parameters needed for login
 * and returns an empty promise. All post-authentication procedures should
 * be handled within this function.
 */
type OnLoginFunction = (params: LoginParams) => Promise<void>;

/**
 * This custom hook returns a function that calls an API endpoint and then
 * creates snackbars based on the response from the API.
 */
export const useLogin = (): { onLogin: OnLoginFunction; loading: boolean } => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const currentEntity = useAppSelector(selectCurrentEntity);
  const navigate = useNavigate();
  const initUser = useInitUser();

  const authState = useAppSelector(selectAuth);

  useEffect(() => {
    if (authState.authenticated && currentEntity) {
      navigate(`/entity/${currentEntity._id}/home`);
    }
  }, [authState, currentEntity]);

  return {
    loading,
    onLogin: async (params) => {
      setLoading(true);
      try {
        await callLogin(params);
        initUser();
        enqueueSnackbar("Successfully logged in");
      } catch (e) {
        enqueueSnackbar(e.message, {
          variant: "error",
        });
        setLoading(false);
      }
    },
  };
};

/**
 * Returns a function that attempts to initialize the user by making a request for user details.
 * As long as the local storage or cookies has an auth token, the user will be returned and the
 * local authentication state will be initialized.
 */
export function useInitUser(): () => Promise<void> {
  const dispatch = useAppDispatch();
  const currentEntity = useAppSelector(selectCurrentEntity);
  return async () => {
    const details = await getUserDetails();
    dispatch(setDefaultEntity(details.entity.serialize()));
    if (!currentEntity) {
      const entity = await getEntityWithAncestors(details.entity._id);
      dispatch(setCurrentEntity(entity.serialize()));
    }
    dispatch(login(details));
  };
}

/**
 * This custom hook returns a single function that can be used to end the current
 * session by deleting the auth_token cookie. After removing the cookie, the user
 * will be redirected to the login page.
 */
export const useLogout = () => {
  const dispatch = useAppDispatch();
  const client = useQueryClient();
  const navigate = useNavigate();
  return {
    onLogout: async () => {
      navigate("/auth/login");
      dispatch(unsetEntityData());
      dispatch(logout());
      Cookies.remove("auth_token");
      Cookies.remove("nxt-token");
      localStorage.removeItem("nxt-token");
      await client.invalidateQueries("auth");
    },
  };
};

export const doLogout = () => {
  Cookies.remove("nxt-token");
  localStorage.removeItem("nxt-token");
  store.dispatch(logout());
};

/**
 * Returns a function that can determine if the currently authenticated user has
 * the required permissions. The returned function accepts the required permissions
 * and returns a boolean value.
 */
export const useHasPermissions = () => {
  const { permissions } = useAppSelector(selectAuth);
  const hasPermissions = useCallback(
    (...requirements: Permission[]): boolean => {
      const needs = requiresPermissions(...requirements);
      const has = parsePermissions(...permissions);
      return needs.isSatisfiedBy(has);
    },
    [permissions]
  );
  return [hasPermissions];
};

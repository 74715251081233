import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Paginated, Pager } from "../api/base";
import {
  GridFeatureMode,
  GridFilterModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { useQuery } from "react-query";
import { QueryObserverOptions } from "react-query/types/core/types";

interface UsePaginatorProps {
  useQueryString: boolean;
  queryStringPrefix?: string;
}

const defaultUsePaginatorProps: UsePaginatorProps = {
  useQueryString: true,
};

/**
 * This custom hook is currently only used internally to this file. It persists
 * paginator state to the query parameters and initializes paginator state from
 * query parameters (if available). It exposes a set of setter functions for mutating
 * the paginator, and exposes the paginator itself so that callers can get access
 * to the updated state.
 */
export const usePaginator = ({
  useQueryString,
  queryStringPrefix,
}: UsePaginatorProps = defaultUsePaginatorProps) => {
  const [params, setParams] = useSearchParams();
  const [state, setState] = useState(Pager.fromQueryParams(params));

  // Changes to the state should be reflected in the query string
  useEffect(() => {
    if (useQueryString) {
      setParams(state.toParams(queryStringPrefix), {
        replace: true,
      });
    }
  }, [useQueryString, state]);

  return {
    paginator: state,
    setSortModel: (sort: GridSortModel) => {
      setState(
        (state) =>
          new Pager({
            ...state,
            sort,
          })
      );
    },
    setFilterModel: (filter: GridFilterModel) => {
      setState(
        (state) =>
          new Pager({
            ...state,
            filter,
          })
      );
    },
    setLimit: (limit: number) => {
      setState(
        (state) =>
          new Pager({
            ...state,
            limit,
          })
      );
    },
    setPage: (page: number) => {
      setState(
        (state) =>
          new Pager({
            ...state,
            page,
          })
      );
    },
  };
};

/**
 * This custom hook uses an internal paginator to call the provided query function
 * every time the pagination parameters change. The hook returns all the standard
 * react-query properties for accessing the query results, in addition to helper
 * functions for working with the pagination state. If you're using this hook to
 * provide data to a mui/DataGrid, you can spread the tableConfig property returned
 * by this hook which will configure the table to automatically use server-side
 * pagination.
 *
 * @example
 * const { data, tableConfig } = usePaginatedQuery('my-query', fetcher)
 *
 * <DataGrid columns={columns} rows={data.rows} {...tableConfig}/>
 *
 * @param queryKey
 * @param queryFn
 * @param pagerOptions
 * @param queryOptions
 */
export function usePaginatedQuery<T>(
  queryKey: string | unknown[],
  queryFn: (pager: Pager) => Promise<Paginated<T>>,
  pagerOptions: UsePaginatorProps = defaultUsePaginatorProps,
  queryOptions: QueryObserverOptions<unknown, unknown, Paginated<T>> = {
    keepPreviousData: true,
  }
) {
  const { paginator, setSortModel, setFilterModel, setPage, setLimit } =
    usePaginator(pagerOptions);
  const paginatedQueryKey = buildQueryKey(queryKey, paginator);
  const queryObject = useQuery(
    paginatedQueryKey,
    () => queryFn(paginator),
    queryOptions
  );
  return {
    ...queryObject,
    queryKey: paginatedQueryKey as string[],
    paginator,
    setSortModel,
    setFilterModel,
    setPage,
    setLimit,
    tableConfig: {
      rows: queryObject.data?.rows ?? [],
      rowCount: queryObject.data?.totalRows ?? 0,
      error: queryObject.error,
      loading: queryObject.isFetching,
      filterMode: <GridFeatureMode>"server",
      sortingMode: <GridFeatureMode>"server",
      paginationMode: <GridFeatureMode>"server",
      onFilterModelChange: setFilterModel,
      filterModel: paginator.filter,
      onSortModelChange: setSortModel,
      sortModel: paginator.sort,
      onPageChange: setPage,
      page: paginator.page,
      onPageSizeChange: setLimit,
      pageSize: paginator.limit,
    },
  };
}

function buildQueryKey(
  queryKey: string | unknown[],
  paginator: Pager
): unknown[] {
  if (Array.isArray(queryKey)) {
    return [
      ...queryKey,
      paginator.page,
      paginator.limit,
      paginator.filter,
      paginator.sort,
    ];
  } else if (typeof queryKey == "string" || typeof queryKey == "object") {
    return [
      queryKey,
      paginator.page,
      paginator.limit,
      paginator.filter,
      paginator.sort,
    ];
  } else {
    throw new Error(`Unknown query key type '${typeof queryKey}': ${queryKey}`);
  }
}

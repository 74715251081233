import { createContext, useState } from "react";

export const PageHeaderContext = createContext({
  header: "",
  setHeader: (header: string) => {},
});

export const PageHeaderContextProvider = ({ children }: any) => {
  const [header, setHeader] = useState("");

  return (
    <PageHeaderContext.Provider
      value={{
        header,
        setHeader,
      }}
    >
      {children}
    </PageHeaderContext.Provider>
  );
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { StyledDataGrid } from "../tables/StyledDataGrid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GridCellParams, GridColumns } from "@mui/x-data-grid-pro";
import { BackgroundColor } from "../../hooks/styles";
import { useQuery } from "react-query";
import { gqlQuery } from "../../api/base";
import { gql } from "@apollo/client";
import { groupBy } from "lodash";
import clsx from "clsx";
import { getWebadminBaseUrl } from "../../logic/webadmin";
import {
  authRemoteTechnician,
  startRemoteTechnician,
} from "../../api/developer";

import sjcl from "sjcl";
import { ExpandMore, Insights } from "@mui/icons-material";
import { RegressionTestResultDiffTable } from "./RegressionTestResultDiffTable";
import Loadable from "react-loadable";
import { resultsToColumns } from "./helpers";
import { RegressionTestInsights } from "./RegressionTestInsights";

const MonacoDiffViewer = Loadable({
  loader: () => import("../MonacoDiffViewer").then((v) => v.MonacoDiffViewer),
  loading: () => <CircularProgress />,
});

export const tableCellStyles = (theme) => ({
  "& .cell.removed": {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    fontWeight: theme.typography.fontWeightBold,
  },
  "& .cell.added": {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    fontWeight: theme.typography.fontWeightBold,
  },
  "& .cell.modified": {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    fontWeight: theme.typography.fontWeightBold,
  },
});

export const RegressionTestDetailsResultsTab = ({ results, isFetching }) => {
  const theme = useTheme();
  const groupedData = useMemo(() => groupModels(results), [results]);

  const columns = useMemo(
    (): GridColumns => [
      {
        field: "model",
        headerName: "Model",
        flex: 1,
      },
      {
        field: "samples",
        headerName: "Samples",
        width: 75,
      },
      {
        field: "valuesAddedCount",
        headerName: "Added",
        width: 75,
        cellClassName: (params: GridCellParams<number>) =>
          clsx("cell", {
            added: params.value > 0,
          }),
      },
      {
        field: "valuesChangedCount",
        headerName: "Modified",
        width: 75,
        cellClassName: (params: GridCellParams<number>) =>
          clsx("cell", {
            modified: params.value > 0,
          }),
      },
      {
        field: "valuesRemovedCount",
        headerName: "Removed",
        width: 75,
        cellClassName: (params: GridCellParams<number>) =>
          clsx("cell", {
            removed: params.value > 0,
          }),
      },
    ],
    []
  );

  const getPanelContent = useCallback(
    ({ row }) => (
      <>
        <ItemGroupDetails results={row.rows} />
        <Divider />
      </>
    ),
    []
  );
  const getPanelHeight = useCallback(({}) => "auto", []);
  const getRowId = useCallback((row) => row.itemKey, []);

  const bg = { backgroundColor: BackgroundColor.Gray };
  return (
    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
      <Box p={2} sx={{ ...bg }}>
        <Grid container columnSpacing={2}>
          <Grid item xs={3}>
            <Paper>
              <Box p={1} pl={2}>
                <Typography variant="caption">Samples</Typography>
                <Typography variant="h1">
                  {groupedData
                    .map((v) => v.samples)
                    .reduce((a, b) => a + b, 0)
                    .toLocaleString("en-US")}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper>
              <Box p={1} pl={2}>
                <Typography variant="caption">Added</Typography>
                <Typography variant="h1">
                  {groupedData
                    .map((v) => v.valuesAddedCount)
                    .reduce((a, b) => a + b, 0)
                    .toLocaleString("en-US")}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper>
              <Box p={1} pl={2}>
                <Typography variant="caption">Modified</Typography>
                <Typography variant="h1">
                  {groupedData
                    .map((v) => v.valuesChangedCount)
                    .reduce((a, b) => a + b, 0)
                    .toLocaleString("en-US")}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper>
              <Box p={1} pl={2}>
                <Typography variant="caption">Removed</Typography>
                <Typography variant="h1">
                  {groupedData
                    .map((v) => v.valuesRemovedCount)
                    .reduce((a, b) => a + b, 0)
                    .toLocaleString("en-US")}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} pt={2}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <ListItem disableGutters={true} disablePadding={true}>
                <ListItemIcon>
                  <Insights />
                </ListItemIcon>
                <ListItemText>Insights</ListItemText>
              </ListItem>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {isFetching && (
                  <Box width="100%">
                    <Skeleton />
                  </Box>
                )}
                {results && <RegressionTestInsights results={results} />}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Divider />
        <StyledDataGrid
          autoHeight={false}
          sx={{
            ...tableCellStyles(theme),
            height: "100%",
          }}
          loading={isFetching}
          getDetailPanelContent={getPanelContent}
          getDetailPanelHeight={getPanelHeight}
          getRowId={getRowId}
          columns={columns}
          disableSelectionOnClick={true}
          rows={groupedData}
          bordered={false}
        />
      </Box>
    </Box>
  );
};

/**
 * Groups an array of regression test results by item and returns each group. The groups
 * contain the original rows of data used for the group.
 * @param data
 */
function groupModels(data: Array<Result>): GroupedResult[] {
  if (data == null || data.length == 0) {
    return [];
  }
  const out: GroupedResult[] = [];
  const results = groupBy(data, (result) => result.device.itemKey);
  const groups = Object.keys(results);
  for (const itemKey of groups) {
    out.push(
      results[itemKey]
        .map((r) => ({
          itemKey: r.device.itemKey,
          model: `${r.device.make} ${r.device.model}`,
          valuesAddedCount: r.valuesAdded.length,
          valuesRemovedCount: r.valuesRemoved.length,
          valuesChangedCount: r.valuesChanged.length,
          samples: 1,
          rows: [r],
        }))
        .reduce((acc, next) => ({
          ...acc,
          valuesAddedCount: acc.valuesAddedCount + next.valuesAddedCount,
          valuesRemovedCount: acc.valuesRemovedCount + next.valuesRemovedCount,
          valuesChangedCount: acc.valuesChangedCount + next.valuesChangedCount,
          samples: acc.samples + next.samples,
          model: next.model,
          rows: acc.rows.concat(next.rows),
        }))
    );
  }
  return out;
}

type GroupedResult = {
  itemKey: string;
  model: string;
  samples: number;
  valuesAddedCount: number;
  valuesChangedCount: number;
  valuesRemovedCount: number;
  rows: Result[];
};

export type Result = {
  id: string;
  valuesAdded: string[];
  valuesChanged: string[];
  valuesRemoved: string[];
  controlDgi: string;
  variableDgi: string;
  controlResult: string;
  variableResult: string;
  device: {
    id: string;
    make: string;
    model: string;
    serialNumber: string;
    firmware: string;
    itemKey: string;
  };
};

export const useGetRegressionTestResults = (regressionTestKey: string) => {
  type Response = {
    id: string;
    results: Result[];
  };
  return useQuery(["regressionTest", regressionTestKey, "results"], () =>
    gqlQuery(
      gql`
        query getRegressionTestResults($regressionTestKey: ObjectId!) {
          regressionTest(regressionTestKey: $regressionTestKey) {
            id
            results {
              id
              valuesAdded
              valuesChanged
              valuesRemoved
              controlDgi
              variableDgi
              controlResult
              variableResult
              device {
                id
                entityKey
                itemKey
                make
                model
                serialNumber
                firmware {
                  device
                }
              }
            }
          }
        }
      `,
      {
        regressionTestKey,
      }
    ).then((res) => (res as any).regressionTest)
  );
};

type ItemGroupDetailsProps = {
  results: Result[];
};

const ItemGroupDetails = ({ results }: ItemGroupDetailsProps) => {
  const theme = useTheme();

  const handleOpenWebpage = async (deviceKey: string) => {
    const jobKey = await startRemoteTechnician(deviceKey);
    const [authToken, clientSecret] = await authRemoteTechnician(
      deviceKey,
      jobKey
    );
    const res = await fetch(
      `https://${deviceKey}.remotetechnician.dev.printtrackerpro.com/_pt_auth`,
      {
        method: "POST",
        body: `auth_token=${authToken}&client_secret=${sjcl.codec.hex.fromBits(
          clientSecret
        )}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
        credentials: "include",
      }
    );
  };

  const columns = useMemo(
    (): GridColumns => [
      {
        field: "model",
        headerName: "Model",
        valueGetter: ({ row }) =>
          `${row.device?.make ?? ""} ${row.device?.model ?? ""}`.trim(),
        flex: 1,
      },
      {
        field: "serialNumber",
        headerName: "Serial Number",
        renderCell: ({ row }) => (
          <a
            target="_blank"
            href={`${getWebadminBaseUrl()}/entity/${
              row.device.entityKey
            }/devices/${row.device.id}/dgi-builder`}
          >
            {row.device?.serialNumber}
          </a>
        ),
        flex: 1,
      },
      {
        field: "firmware",
        headerName: "Firmware",
        valueGetter: ({ row }) => row.device?.firmware?.device,
        flex: 1,
      },
      {
        field: "actions",
        headerName: "",
        renderCell: ({ row }) => {
          return (
            <Button onClick={() => handleOpenWebpage(row.device.id)}>
              Webpage
            </Button>
          );
        },
        flex: 1,
      },
      {
        field: "valuesAddedCount",
        headerName: "Added",
        width: 75,
        valueGetter: ({ row }) => row.valuesAdded.length,
        cellClassName: (params: GridCellParams<number>) =>
          clsx("cell", {
            added: params.value > 0,
          }),
      },
      {
        field: "valuesChangedCount",
        headerName: "Modified",
        width: 75,
        valueGetter: ({ row }) => row.valuesChanged.length,
        cellClassName: (params: GridCellParams<number>) =>
          clsx("cell", {
            modified: params.value > 0,
          }),
      },
      {
        field: "valuesRemovedCount",
        headerName: "Removed",
        width: 75,
        valueGetter: ({ row }) => row.valuesRemoved.length,
        cellClassName: (params: GridCellParams<number>) =>
          clsx("cell", {
            removed: params.value > 0,
          }),
      },
    ],
    []
  );
  return (
    <>
      <Box ml={3} sx={{ borderLeft: `1px solid ${theme.palette.divider}` }}>
        <Divider />
        <StyledDataGrid
          sx={{
            ...tableCellStyles(theme),
          }}
          getDetailPanelContent={({ row }) => (
            <DeviceDiffDetails result={row} />
          )}
          columns={columns}
          rows={results}
          bordered={false}
          disableSelectionOnClick={true}
        />
      </Box>
    </>
  );
};

type DeviceDiffDetailsProps = {
  result: Result;
};
const DeviceDiffDetails = ({ result }: DeviceDiffDetailsProps) => {
  const [tab, setTab] = useState(0);
  const handleTabChange = (event, index: number) => {
    setTab(index);
  };

  function removeProperty(json, prop) {
    for (const i in json) {
      if (json.hasOwnProperty(i)) {
        if (typeof json[i] == "object") {
          removeProperty(json[i], prop);
        } else if (i == prop) {
          delete json[i];
        }
      }
    }
  }

  const format = useCallback((input) => {
    const obj = JSON.parse(input);

    // Remove the priority property because it's only used by specific DGIs
    removeProperty(obj, "priority");
    return JSON.stringify(obj, null, 2);
  }, []);

  return (
    <>
      <Divider />
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Box sx={{ backgroundColor: "white" }}>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label="Meters" />
            <Tab label="Meters (diff)" />
            <Tab label="DGIs" />
          </Tabs>
        </Box>
        <Divider />
        {tab === 0 && <RegressionTestResultDiffTable result={result} />}
        {tab === 1 && (
          <MonacoDiffViewer
            left={format(result.controlResult)}
            right={format(result.variableResult)}
            language={"json"}
          />
        )}
        {tab === 2 && (
          <MonacoDiffViewer
            left={format(result.controlDgi)}
            right={format(result.variableDgi)}
            language={"json"}
          />
        )}
      </Box>
    </>
  );
};

import {
  Box,
  Collapse,
  CSSObject,
  Divider,
  Drawer as MuiDrawer,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  styled,
  Theme,
  Tooltip,
  useTheme,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRight,
  Circle,
  Computer,
  CreditCard,
  Engineering,
  Link,
} from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import { useNavigateWithEntity } from "../../hooks/navigate";
import { useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.scss";
import { useAppSelector } from "../../store/store";
import { selectCurrentEntity } from "../../store/entity";
import { useHasPermissions } from "../../hooks/auth";
import { Permissions } from "../../logic/auth";

const drawerWidth = 240;

export const Sidebar = ({ open }) => {
  const theme = useTheme();
  const location = useLocation();
  const entity = useAppSelector(selectCurrentEntity);
  // const [expandManage, setExpandManage] = useState(
  //   location.pathname.includes("/manage")
  // );
  // const [expandAdmin, setExpandAdmin] = useState(
  //   location.pathname.includes("/manage")
  // );
  // const [expandSales, setExpandSales] = useState(
  //   location.pathname.includes("/sales")
  // );

  const [hasPermissions] = useHasPermissions();

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton>
          {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      {/*<List subheader={<CollapsedHeader open={open} header={"General"} />}>*/}
      {/*<SidebarItem*/}
      {/*  open={open}*/}
      {/*  name="Home"*/}
      {/*  page="/home"*/}
      {/*  icon={<House />}*/}
      {/*  entityScoped={true}*/}
      {/*/>*/}
      {hasPermissions(Permissions.Billing.Manage) && (
        <SidebarItem
          open={open}
          name="Billing"
          page="/billing"
          icon={<CreditCard />}
          entityScoped={true}
          disabled={entity?.isDealer === false}
        />
      )}
      {hasPermissions(Permissions.DaemonOnly) && (
        <SidebarItem
          open={open}
          name="Installs"
          page="/installs"
          icon={<Computer />}
          entityScoped={true}
        />
      )}
      {hasPermissions(Permissions.Integrations.List) && (
        <SidebarItem
          open={open}
          name="Integrations"
          page="/integrations"
          icon={<Link />}
          entityScoped={true}
        />
      )}
      {hasPermissions(Permissions.DaemonOnly) && (
        <SidebarItem
          open={open}
          name="Developer"
          page="/developer"
          icon={<Engineering />}
          entityScoped={false}
        />
      )}
      {/*</List>*/}
    </Drawer>
  );
};

/**
 * This is a child component of the sidebar. It automatically determines if it's
 * the selected page based on the current page location and highlights itself.
 * @param name
 * @param page
 * @param icon
 * @param indent
 * @param {boolean} entityScoped
 * @constructor
 */
const SidebarItem = ({
  name,
  open,
  page,
  icon,
  indent,
  entityScoped,
  disabled,
}: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const navigateEntity = useNavigateWithEntity();
  const isSelected = useCallback(
    (path: string) => {
      return new RegExp(`${path}$|${path}/`).test(location.pathname);
    },
    [location]
  );
  const [selected, setSelected] = useState(isSelected(page));

  useEffect(() => {
    setSelected(isSelected(page));
  }, [location]);

  const handleNavigate = () => {
    if (entityScoped) {
      navigateEntity(page);
    } else {
      navigate(page);
    }
  };

  const content = (
    <ListItem disablePadding sx={{ display: "block" }}>
      <ListItemButton
        disabled={disabled}
        // sx={indent ? { pl: indent } : null}
        sx={{
          pl: indent,
          justifyContent: open ? "initial" : "center",
        }}
        onClick={handleNavigate}
        selected={selected}
      >
        <ListItemIcon
          className={selected ? "selected" : ""}
          sx={{ mr: open ? 3 : "auto", justifyContent: "center" }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          className={selected ? "selected" : ""}
          sx={{ opacity: open ? 1 : 0 }}
        >
          {name}
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
  if (disabled) {
    return (
      <Tooltip
        title={
          "This entity is not a dealer entity, and is not responsible for billing. Please select your dealer entity in order to configure billing."
        }
      >
        {content}
      </Tooltip>
    );
  }
  return content;
};

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const CollapsedHeader = ({ open, header }): JSX.Element => {
  if (open) {
    return (
      <Grow in={open}>
        <ListSubheader component="div">{header}</ListSubheader>
      </Grow>
    );
  } else {
    return (
      <Grow in={!open}>
        <ListSubheader
          component="div"
          sx={{
            width: "10px",
            textAlign: "center",
            marginTop: "0.0em",
            paddingLeft: 3.4,
            paddingTop: 0.0,
          }}
        >
          <Circle sx={{ width: "10px", verticalAlign: "middle" }} />
        </ListSubheader>
      </Grow>
    );
  }
};

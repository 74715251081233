import { fetchBase } from "./base";
import {
  Customer,
  InitializeSetupIntent,
  InvoiceDetails,
  InvoiceSummary,
  PaymentMethod,
} from "./types/billing";

export const getInvoices = (
  entityKey: string
): Promise<Array<InvoiceSummary>> => {
  return fetchBase(`/entity/${entityKey}/billing/invoices`, {
    method: "GET",
  }).then((res: any[]) => (res ?? []).map((r) => new InvoiceSummary(r)));
};

export const getInvoiceDetails = (
  entityKey: string,
  referenceNbr: string
): Promise<{ invoice: InvoiceDetails; customer: Customer }> => {
  return fetchBase(`/entity/${entityKey}/billing/invoices/${referenceNbr}`, {
    method: "GET",
  }).then((res: any) => ({
    invoice: new InvoiceDetails(res.invoice),
    customer: new Customer(res.customer),
  }));
};

export const getPaymentMethods = (
  entityKey: string
): Promise<Array<PaymentMethod>> => {
  return fetchBase(`/entity/${entityKey}/billing/paymentMethods`, {
    method: "GET",
  }).then((res: any[]) => (res ?? []).map((v) => new PaymentMethod(v)));
};

export const togglePaymentMethodAutoPay = (
  entityKey: string,
  paymentMethodId: string,
  autoPayEnabled: boolean
): Promise<any> => {
  return fetchBase(
    `/entity/${entityKey}/billing/paymentMethods/${paymentMethodId}`,
    {
      method: "PUT",
      body: JSON.stringify({ autoPayEnabled }),
    }
  );
};

export const createSetupIntent = (
  entityKey: string
): Promise<InitializeSetupIntent> => {
  return fetchBase(`/entity/${entityKey}/billing/paymentMethods/setupIntent`, {
    method: "POST",
  }).then((res: any) => new InitializeSetupIntent(res));
};

export const payInvoices = (
  entityKey: string,
  invoiceReferenceNumbers: string[],
  paymentMethodId: string,
  amount: number
): Promise<InitializeSetupIntent> => {
  return fetchBase(`/entity/${entityKey}/billing/payInvoices`, {
    method: "POST",
    body: JSON.stringify({
      invoiceReferenceNumbers,
      paymentMethodId,
      amount,
    }),
  });
};

import { useWatchEntityKeyParam } from "../../hooks/navigate";
import { usePageHeader } from "../../hooks/header";
import { BackgroundColor, useBackgroundColor } from "../../hooks/styles";
import { PageHeaderActionsPortal } from "../../components/containers/PageHeader";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { InvoicePage } from "../../components/billing/InvoicePage";
import { useGetInvoiceDetails } from "../../hooks/billing";
import { useAppSelector } from "../../store/store";
import { selectCurrentEntity } from "../../store/entity";
import { InvoiceStatusLabel } from "../../components/billing/InvoiceStatusLabel";

export const BillingInvoicePage = () => {
  const theme = useTheme();

  useWatchEntityKeyParam();
  usePageHeader("Invoice");
  useBackgroundColor(BackgroundColor.White);

  const { referenceNbr } = useParams();

  const entity = useAppSelector(selectCurrentEntity);
  const { data, isFetching } = useGetInvoiceDetails(
    entity?.entityKey,
    referenceNbr
  );

  return (
    <>
      <PageHeaderActionsPortal>
        <Grid container spacing={2}>
          <Grid item xs="auto">
            <Typography variant="h2" color={theme.palette.grey["500"]}>
              {referenceNbr}
            </Typography>
          </Grid>
          <Grid item>
            <Box p={0.4}>
              {data && (
                <InvoiceStatusLabel labelizePaid status={data.invoice.status} />
              )}
            </Box>
          </Grid>
          <Grid item xs="auto" pr={3}></Grid>
        </Grid>
      </PageHeaderActionsPortal>
      <InvoicePage referenceNbr={referenceNbr} />
    </>
  );
};

import { Box, Button, Grid, Skeleton } from "@mui/material";
import { PageHeaderActionsPortal } from "../../components/containers/PageHeader";
import { usePageHeader } from "../../hooks/header";
import { BackgroundColor, useBackgroundColor } from "../../hooks/styles";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useParams, useSearchParams } from "react-router-dom";
import { useInstall } from "../../api/install";
import { PowerSettingsNew, RestartAlt } from "@mui/icons-material";
import { InstallDetailsRoot } from "../../components/installs/InstallDetailsRoot";
import { setGlobalLoading } from "../../store/loading";

export const InstallDetailsPage = ({}) => {
  const { installKey } = useParams();
  const [params] = useSearchParams();
  const { data, isFetching } = useInstall(installKey);
  const [header, setHeader] = usePageHeader(params.get("machineName"));
  useBackgroundColor(BackgroundColor.Gray);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setGlobalLoading(isFetching));
  }, [isFetching]);

  // Wait until we can get the machineName from the query params
  useEffect(() => {
    if (data?.install) setHeader(data.install.machineName);
  }, [data]);
  return (
    <>
      <PageHeaderActionsPortal>
        <Grid container spacing={2}>
          <Grid item xs>
            <Box>
              <Grid item xs>
                {/*<EntityBreadcrumbPageHeader />*/}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs="auto" pr={3}>
            <Grid container columnSpacing={2}>
              <Grid item>
                <Button color="warning" size="small" startIcon={<RestartAlt />}>
                  Restart
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="error"
                  size="small"
                  startIcon={<PowerSettingsNew />}
                >
                  Shutdown
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PageHeaderActionsPortal>
      {data != null && <InstallDetailsRoot install={data.install} />}
    </>
  );
};

import { Grid, Link } from "@mui/material";
import {
  LineUp,
  LineUpAllColumns,
  LineUpCategoricalColumnDesc,
  LineUpColumn,
  LineUpColumnDesc,
  LineUpImposeColumn,
  LineUpNumberColumnDesc,
  LineUpRanking,
  LineUpStringColumnDesc,
  LineUpSupportColumn,
} from "lineupjsx";
import { useMemo } from "react";
import { flattenKeys } from "./helpers";
import { flatten } from "lodash";

export const RegressionTestDetailsValueExplorerTab = ({ results }) => {
  const data = useMemo(
    () =>
      results.map((v) => {
        let obj = {};
        obj["make"] = v.device.make;
        obj["model"] = v.device.model;
        obj = Object.assign(obj, flattenKeys(JSON.parse(v.variableResult)));
        // obj["deviceKey"] = v.device.id;
        // obj["entityKey"] = v.device.entityKey;
        Object.keys(obj).forEach(
          (key) => key.includes("displayName") && delete obj[key]
        );
        return obj;
      }),
    [results]
  );

  // Get all possible key names in the data
  const keys = useMemo(() => {
    const keys: string[] = [
      ...new Set<string>(flatten(data.map((v) => Object.keys(v)))).keys(),
    ];

    // Sort the keys with make, model, firmware at the front and then alphabetically
    const order = [
      "make",
      "model",
      "serialNum",
      "firmware",
      "link",
      "lcdDisplay",
    ];
    keys.sort((a, b) => {
      const aIdx = order.indexOf(a);
      const bIdx = order.indexOf(b);
      if (aIdx === -1 && bIdx === -1) {
        return a.localeCompare(b);
      } else if (aIdx === -1) {
        return 1;
      } else if (bIdx === -1) {
        return -1;
      } else {
        return aIdx - bIdx;
      }
    });
    return keys;
  }, [data]);

  return (
    <Grid container height="100%" p={0}>
      <Grid item xs={12}>
        <LineUp data={data} style={{ height: "100%" }} animated={false}>
          {/*<LineUpCategoricalColumnDesc column={"make"} />*/}
          {/*<LineUpCategoricalColumnDesc column={"model"} />*/}
          {/*<LineUpStringColumnDesc column={"serialNum"} />*/}
          {/*<LineUpCategoricalColumnDesc column={"firmware"} />*/}

          {/*<LineUpRanking groupBy={["make", "model", "firmware"]}>*/}
          {/*  <LineUpSupportColumn type={"*"} />*/}
          {/*</LineUpRanking>*/}
        </LineUp>
      </Grid>
    </Grid>
  );
};

import { Divider, styled } from "@mui/material";
import { ForwardedRef, forwardRef, memo } from "react";
import { Input, InputProps } from "@mui/base";

const StyledInputElement = styled("input")(({ theme }) => {
  return `
    width: 100%;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px 12px;
    border: 0;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    transition: 0.3s;
  `;
});

const TableFilterInput = memo(
  forwardRef((props: InputProps, ref: ForwardedRef<any>) => {
    return (
      <Input
        slots={{ input: StyledInputElement }}
        ref={ref}
        {...props}
        value={props.value ?? ""}
      />
    );
  })
);

type SuppliesTableHeaderProps = InputProps & {};

export const TableToolbarFilter = memo((props: SuppliesTableHeaderProps) => {
  return (
    <>
      <Divider />
      <TableFilterInput {...props} />
      <Divider />
    </>
  );
});

import { useEffect } from "react";

export enum BackgroundColor {
  White = "#FFFFFF",
  Gray = "#F6F8FA",
}

export const useBackgroundColor = (color: BackgroundColor) => {
  useEffect(() => {
    document.body.style.backgroundColor = color;
  }, []);
};

import { Navigate, Route, Routes } from "react-router-dom";
import { SecuredContainer } from "./components/containers/SecuredContainer";
import { PageHeaderContextProvider } from "./state/page-header";
import { Home } from "./pages/home";
import { useAppSelector } from "./store/store";
import { selectDefaultEntity } from "./store/entity";
import { BillingInvoicePage } from "./pages/billing/BillingInvoicePage";
import { Permissions } from "./logic/auth";
import { RequirePermissions } from "./components/containers/RequirePermissions";
import { InstallDetailsPage } from "./pages/installs/InstallDetailsPage";
import { WindowRenderer } from "./components/containers/WindowRenderer";
import Loadable from "react-loadable";
import { CircularProgress } from "@mui/material";

// Lazy loading pages
const DeveloperPage = Loadable({
  loader: () =>
    import("./pages/developer/DeveloperPage").then((v) => v.DeveloperPage),
  loading: () => <CircularProgress />,
});
const DownloadPage = Loadable({
  loader: () => import("./pages/download/Download").then((v) => v.DownloadPage),
  loading: () => <CircularProgress />,
});
const InstallsPage = Loadable({
  loader: () =>
    import("./pages/installs/InstallsPage").then((v) => v.InstallsPage),
  loading: () => <CircularProgress />,
});
const BillingPage = Loadable({
  loader: () =>
    import("./pages/billing/BillingPage").then((v) => v.BillingPage),
  loading: () => <CircularProgress />,
});
const LoginPage = Loadable({
  loader: () => import("./pages/auth/Login").then((v) => v.Login),
  loading: () => <CircularProgress />,
});
const IntegrationsPage = Loadable({
  loader: () =>
    import("./pages/integrations/IntegrationsPage").then(
      (v) => v.IntegrationsPage
    ),
  loading: () => <CircularProgress />,
});

function App() {
  return (
    <>
      <Routes>
        <Route
          path="*"
          element={
            <PageHeaderContextProvider>
              <SecuredContainer>
                <Routes>
                  <Route
                    path="/entity/:entityKey/installs"
                    element={
                      <RequirePermissions
                        permissions={[Permissions.DaemonOnly]}
                      >
                        <InstallsPage />
                      </RequirePermissions>
                    }
                  />
                  <Route
                    path="/installs/:installKey"
                    element={
                      <RequirePermissions
                        permissions={[Permissions.DaemonOnly]}
                      >
                        <InstallDetailsPage />
                      </RequirePermissions>
                    }
                  />
                  <Route
                    path="/entity/:entityKey/integrations"
                    element={
                      <RequirePermissions
                        permissions={[Permissions.Integrations.List]}
                      >
                        <IntegrationsPage />
                      </RequirePermissions>
                    }
                  />
                  <Route
                    path="/entity/:entityKey/billing"
                    element={
                      <RequirePermissions
                        permissions={[Permissions.Billing.Manage]}
                      >
                        <BillingPage />
                      </RequirePermissions>
                    }
                  />

                  <Route
                    path="/developer"
                    element={
                      <RequirePermissions
                        permissions={[Permissions.DaemonOnly]}
                      >
                        <DeveloperPage />
                      </RequirePermissions>
                    }
                  />
                  <Route
                    path="/entity/:entityKey/billing/invoices/:referenceNbr"
                    element={<BillingInvoicePage />}
                  />
                  <Route path="/entity/:entityKey/home" element={<Home />} />
                  <Route path="*" element={<DefaultRoute />} />
                </Routes>
                <WindowRenderer />
              </SecuredContainer>
            </PageHeaderContextProvider>
          }
        />
        <Route path="/auth/login" element={<LoginPage />} />
        <Route path="/download" element={<DownloadPage />} />
      </Routes>
    </>
  );
}

const DefaultRoute = () => {
  const defaultEntity = useAppSelector(selectDefaultEntity);
  if (!defaultEntity) {
    return <Navigate to="/auth/login" replace />;
  }
  return <Navigate to={`/entity/${defaultEntity.entityKey}/home`} replace />;
};

export default App;

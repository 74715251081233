import { useAppDispatch, useAppSelector } from "../../store/store";
import {
  closeWindow,
  selectWindows,
  updateWindowState,
  WindowConfiguration,
  WindowState,
  WindowType,
} from "../../store/windows";
import { useCallback, useRef } from "react";
import WinBox from "react-winbox";
import { debounce } from "lodash";
import { Box, CircularProgress, Grid } from "@mui/material";
import { useGetRegressionTest } from "../../api/developer";
import { RegressionTestDetails } from "../developer/RegressionTestDetails";
import "winbox/dist/css/winbox.min.css";

export const WindowRenderer = ({}) => {
  const windowState = useAppSelector(selectWindows);
  return (
    <>
      {windowState.windows.map((w) => (
        <WinBoxWrapper key={w.id} {...w} />
      ))}
    </>
  );
};

const WinBoxWrapper = (props: WindowConfiguration) => {
  const dispatch = useAppDispatch();
  const ref = useRef();

  const handleWindowMove = debounce((x: number, y: number) => {
    dispatch(updateWindowState({ id: props.id, windowState: { x, y } }));
  }, 50);

  const handleWindowResize = debounce((width: number, height: number) => {
    dispatch(
      updateWindowState({ id: props.id, windowState: { width, height } })
    );
  }, 50);
  const handleCloseWindow = () => {
    dispatch(closeWindow({ id: props.id }));
  };

  const handleFocus = useCallback(() => {
    if (ref?.current) {
      // @ts-ignore
      ref.current.focus();
    }
  }, [ref]);

  return (
    <WinBox
      ref={ref}
      title={""}
      key={props.id}
      hide={props.payload.state === WindowState.Closed}
      onMove={handleWindowMove}
      onResize={handleWindowResize}
      onClose={handleCloseWindow}
      onFocus={() => handleFocus()}
      {...props.windowState}
    >
      <WindowContainer {...props} />
    </WinBox>
  );
};

const WindowContainer = (props: WindowConfiguration) => {
  switch (props.type) {
    case WindowType.DeveloperToolsRegressionTest:
      const { data, isFetching } = useGetRegressionTest(props.payload.id);
      if (isFetching) {
        return (
          <Box sx={{ boxSizing: "border-box", height: "100%" }}>
            {isFetching && <CenterLoadingSpinner />}
          </Box>
        );
      } else {
        return <RegressionTestDetails regressionTest={data} />;
      }
    default:
      return (
        <Box sx={{ boxSizing: "border-box", height: "100%" }}>
          <CenterLoadingSpinner />
        </Box>
      );
  }
};

const CenterLoadingSpinner = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ boxSizing: "border-box", height: "100%" }}
    >
      <Grid item xs={1}>
        <CircularProgress variant="indeterminate" />
      </Grid>
    </Grid>
  );
};

import { Box, Grid, Paper, Skeleton, Typography } from "@mui/material";
import { PageHeaderActionsPortal } from "../../components/containers/PageHeader";
import { EntityBreadcrumbPageHeader } from "../../components/EntityBreadcrumbPageHeader";
import { usePageHeader } from "../../hooks/header";
import { useWatchEntityKeyParam } from "../../hooks/navigate";
import { BackgroundColor, useBackgroundColor } from "../../hooks/styles";
import { useAppSelector } from "../../store/store";
import { selectCurrentEntity } from "../../store/entity";
import {
  useGetEntityBillableDevicesOverTime,
  useGetEntityReportingDevices,
} from "../../hooks/entity";
import { ReportingDevicesBar } from "../../components/viz/ReportingDevicesBar";
import { BillableDeviceTimeSeries } from "../../components/viz/BillableDeviceTimeSeries";
import moment from "moment";
import { useReportingInstallsBinned } from "../../hooks/install";
import { ReportingInstallsBinned } from "../../components/viz/ReportingInstallsBinned";
import { Masonry } from "@mui/lab";

export const Home = ({}) => {
  useWatchEntityKeyParam();
  usePageHeader("Home");
  useBackgroundColor(BackgroundColor.Gray);

  const entity = useAppSelector(selectCurrentEntity);
  const { data: reporting } = useGetEntityReportingDevices(entity?.entityKey);
  const { data: billable } = useGetEntityBillableDevicesOverTime(
    entity?.entityKey
  );
  const { data: reportingInstalls } = useReportingInstallsBinned(
    entity?.entityKey
  );

  return (
    <>
      <PageHeaderActionsPortal>
        <Grid container spacing={2}>
          <Grid item>
            <EntityBreadcrumbPageHeader />
          </Grid>
          <Grid item xs>
            <Box>
              <Grid item xs>
                {/*  Buttons */}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs="auto" pr={3}>
            {/*<PageHeaderCartIcon />*/}
          </Grid>
        </Grid>
      </PageHeaderActionsPortal>

      {/*<Box pt={2} pr={2} pl={2}>*/}
      {/*  <Paper>*/}
      {/*    <Box p={5} style={{ textAlign: "center" }}>*/}
      {/*      <Grid container>*/}
      {/*        <Grid xs={4}></Grid>*/}
      {/*        <Grid xs={4}>*/}
      {/*          <Typography variant="h1" style={{ fontWeight: 800 }}>*/}
      {/*            What is this?*/}
      {/*          </Typography>*/}
      {/*          <Typography variant="body2">*/}
      {/*            You're looking at version two of the Print Tracker webadmin.*/}
      {/*            This version is still under active development, and you should*/}
      {/*            use this version side-by-side with the older version.*/}
      {/*          </Typography>*/}
      {/*        </Grid>*/}
      {/*        <Grid xs={4}></Grid>*/}
      {/*      </Grid>*/}
      {/*    </Box>*/}
      {/*  </Paper>*/}
      {/*</Box>*/}
      <Box p={2}>
        <Masonry
          columns={{ xs: 1, md: 2, lg: 3 }}
          spacing={2}
          sx={{ width: "auto" }}
        >
          <Paper>
            <Box p={2}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h1">
                    {moment().format("MMMM")} devices
                  </Typography>
                  <Typography variant="caption">
                    The number of billable and reporting devices for the current
                    month
                  </Typography>
                </Grid>
                <Grid item xs={12} pt={2}>
                  {!reporting && <Skeleton />}
                  {reporting && <ReportingDevicesBar stats={reporting} />}
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <Paper>
            <Box p={2}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h1">Billable Devices</Typography>
                  <Typography variant="caption">
                    The number of billable devices over time
                  </Typography>
                </Grid>
                <Grid item xs={12} pt={2}>
                  {!billable && <Skeleton />}
                  {billable && <BillableDeviceTimeSeries data={billable} />}
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <Paper>
            <Box p={2}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h1">Reporting Installs</Typography>
                  <Typography variant="caption">
                    The number of installs that have checked in within relative
                    time windows
                  </Typography>
                </Grid>
                <Grid item xs={12} pt={2}>
                  {!reportingInstalls && <Skeleton />}
                  {reportingInstalls && (
                    <ReportingInstallsBinned data={reportingInstalls} />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Masonry>
      </Box>
    </>
  );
};

/**
 * A utility function that always hits the correct version of the NexToner API with
 * the appropriate parameters and headers. This is used only internally by API helpers
 * @param {string} path - The URL path after '/api/v1'
 * @param {RequestInit} params - Any additional request parameters
 */
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro";
import Cookies from "js-cookie";
import { request } from "graphql-request";
import { GraphQLError, GraphQLResponse } from "graphql-request/dist/types";
import { store } from "../store/store";
import { logout } from "../store/auth";

function doLogout() {
  Cookies.remove("nxt-token");
  localStorage.removeItem("nxt-token");
  store.dispatch(logout());
}

export const getUrl = (path: string) => {
  const { protocol, hostname } = window.location;
  const isLocal =
    hostname.includes("127.0.0.1") || hostname.includes("localhost");
  return `${protocol}//${hostname}${isLocal ? ":8080" : ""}/api/v1${path}`;
};

const getAuthHeader = () => {
  const token = localStorage.getItem("nxt-token") ?? Cookies.get("nxt-token");
  if (token != null && token.length > 0) {
    return `Bearer ${token}`;
  }
  return null;
};

export function fetchBase<T>(path: string, params?: RequestInit): Promise<T> {
  return fetch(getUrl(path), {
    credentials: "include",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthHeader(),
    },
    ...params,
  }).then((res) => {
    if (!res.ok) {
      return res.json().then((data) => {
        const status = new Status(data);
        if (status.code == 401 && status.message.includes("Token is invalid")) {
          doLogout();
        }
        console.error(status);
        throw status;
      });
    }
    return res.json();
  });
}

type GqlQueryParams = {
  query: any;
  variables: any;
};

export function gqlQuery<T>(query, variables) {
  return request<T>(getUrl("/graphql"), query, variables, {
    Authorization: getAuthHeader(),
  });
}

export function uploadFile(formData: FormData, purpose: string) {
  return fetch(getUrl(`/file/upload?for=${purpose}`), {
    credentials: "include",
    mode: "cors",
    headers: {
      Authorization: getAuthHeader(),
    },
    method: "POST",
    body: formData,
  }).then((res) => {
    if (!res.ok) {
      return res.json().then((data) => {
        const status = new Status(data);
        if (status.code == 401 && status.message.includes("Token is invalid")) {
          doLogout();
        }
        console.error(status);
        throw status;
      });
    }
    return res;
  });
}

export enum StatusReason {
  Unknown = "",
  Unauthorized = "Unauthorized",
  Forbidden = "Forbidden",
  NotFound = "NotFound",
  AlreadyExists = "AlreadyExists",
  Conflict = "Conflict",
  Invalid = "Invalid",
  ServerTimeout = "ServerTimeout",
  Timeout = "Timeout",
  TooManyRequests = "TooManyRequests",
  BadRequest = "BadRequest",
  MethodNotAllowed = "MethodNotAllowed",
  NotAcceptable = "NotAcceptable",
  RequestEntityTooLarge = "RequestEntityTooLarge",
  UnsupportedMediaType = "UnsupportedMediaType",
  InternalError = "InternalError",
  ServiceUnavailable = "ServiceUnavailable",
}

export interface IStatus {
  status: string;
  message: string;
  code: number;
  reason: StatusReason;
  details?: any;
}

export class Status implements IStatus {
  public status: string;
  public message: string;
  public code: number;
  public reason: StatusReason;
  public details?: any;

  constructor(props: IStatus) {
    this.status = props.status;
    this.message = props.message;
    this.code = props.code;
    this.reason = props.reason ?? StatusReason.Unknown;
    this.details = props.details;
  }
}

export class Pager {
  public page: number = 0;
  public limit: number = 30;
  public filter?: GridFilterModel;
  public sort?: GridSortModel;

  constructor(props?: any) {
    if (props) {
      this.page = props.page;
      this.limit = props.limit;
      this.filter = props.filter;
      this.sort = props.sort;
    }
  }

  public toParams(prefix?: string): URLSearchParams {
    const params = new URLSearchParams();
    if (this.page >= 1) {
      params.set(`${prefix ?? ""}page`, this.page.toString());
    }
    if (this.limit >= 1) {
      params.set(`${prefix ?? ""}limit`, this.limit.toString());
    }
    if (this.filter) {
      params.set(
        `${prefix ?? ""}filter`,
        encodeURIComponent(JSON.stringify(this.filter))
      );
    }
    if (this.sort) {
      params.set(
        `${prefix ?? ""}sort`,
        encodeURIComponent(JSON.stringify(this.sort))
      );
    }
    return params;
  }

  public static fromQueryParams(
    queryParams: URLSearchParams,
    prefix?: string
  ): Pager {
    const params = new Pager();
    params.page = +(queryParams.get(`${prefix ?? ""}page`) ?? params.page);
    params.limit = +(queryParams.get(`${prefix ?? ""}limit`) ?? params.limit);
    const encodedFilter = queryParams.get(`${prefix ?? ""}filter`);
    if (encodedFilter) {
      try {
        params.filter = JSON.parse(decodeURIComponent(encodedFilter));
      } catch {}
    }
    const encodedSort = queryParams.get(`${prefix ?? ""}sort`);
    if (encodedSort) {
      try {
        params.sort = JSON.parse(decodeURIComponent(encodedSort));
      } catch {}
    }
    return params;
  }
}

export class Paginated<T> {
  public totalRows: number = 0;
  public rows: T[] = [];
  constructor(props) {
    this.rows = props.rows ?? this.rows;
    this.totalRows = props.totalRows ?? props.totalRows;
  }
}

/**
 * Converts an object of key value pairs (value type must be string or convertable to a string)
 * into a URL search parameter object
 * @param obj
 */
export function toParams(obj: Object): URLSearchParams {
  const params = new URLSearchParams();
  for (const key of Object.keys(obj)) {
    let value = obj[key];
    switch (typeof value) {
      case "string":
        break;
      case "number":
        value = value.toString(10);
        break;
      default:
        value = JSON.stringify(value);
        continue;
    }
    params.set(key, value);
  }
  return params;
}

export function getGqlErrors(error: any): GraphQLError[] {
  if (error == null) return [];
  let container: GraphQLResponse = JSON.parse(JSON.stringify(error));
  return container.response.errors;
}

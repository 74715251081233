import {
  DataGridPro,
  DataGridProProps,
  GridLinkOperator,
  GridSlotsComponent,
} from "@mui/x-data-grid-pro";
import { TableToolbarFilter } from "./TableToolbarFilter";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

export type StyledDataGridProps = DataGridProProps & {
  onContextMenu?: (row) => void;
  /**
   * Determines whether the table should be bordered or not.
   */
  bordered?: boolean;
  /**
   * Determines the placeholder for the header filter input. The header filter
   * is shown if a valid value for this property is provided.
   */
  headerFilterPlaceholder?: string;
};

/**
 * StyledDataGrid is a wrapper around DataGrid that provides a 100% compatible
 * API with the mui/DataGrid. This wrapper applies the default data grid style
 * that is used in this project.
 *
 * @param columns
 * @param rows
 * @param sx
 * @param rowsPerPageOptions
 * @param onContextMenu
 * @param {boolean} bordered
 * @param rest
 * @constructor
 */
export const StyledDataGrid = ({
  columns,
  rows,
  sx,
  onContextMenu,
  bordered,
  headerFilterPlaceholder,
  components,
  ...rest
}: StyledDataGridProps) => {
  const [headerFilter, setHeaderFilter] = useState(
    rest?.filterModel?.items?.find(
      (item) => item.columnField === "_defaultFilter"
    )?.value ?? null
  );
  const [debouncedFilter] = useDebounce(headerFilter, 350);
  const handleContextMenu = useCallback(
    (event) => {
      if (onContextMenu) onContextMenu(event);
    },
    [onContextMenu]
  );

  /**
   * Handles changes to the header filter
   * @param event
   */
  const handleHeaderFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHeaderFilter(event.target.value);
  };

  const buildComponents = useCallback(() => {
    const extensions: Partial<GridSlotsComponent> = {};
    if (!!headerFilterPlaceholder) {
      extensions.Toolbar = TableToolbarFilter;
    }
    return {
      ...components,
      ...extensions,
    };
  }, [components]);

  /**
   * Changes to the global header filter should change the filter model
   */
  useEffect(() => {
    if (debouncedFilter != null) {
      rest.onFilterModelChange(
        {
          linkOperator: rest.filterModel?.linkOperator ?? GridLinkOperator.And,
          items: [
            {
              columnField: "_defaultFilter",
              value: debouncedFilter,
              operatorValue: "contains",
            },
          ],
        },
        null
      );
    }
  }, [debouncedFilter]);

  return (
    <DataGridPro
      autoHeight
      components={buildComponents()}
      componentsProps={{
        toolbar: {
          placeholder: headerFilterPlaceholder,
          onChange: handleHeaderFilterChange,
          value: headerFilter,
        },
        row: {
          onContextMenu: handleContextMenu,
          // style: { cursor: !!onContextMenu ? "context-menu" : "" },
        },
      }}
      pagination={true}
      columns={columns}
      rows={rows}
      rowsPerPageOptions={[30, 50, 100]}
      density="compact"
      {...rest}
      sx={{
        ...sx,
        ...(bordered === false ? { border: 0 } : {}),
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "whitesmoke",
        },
      }}
    />
  );
};

export enum InvoiceStatus {
  Open = "Open",
  Balanced = "Balanced",
  Closed = "Closed",
  CreditHold = "Credit Hold",
}

export namespace InvoiceStatus {
  export function toDisplayName(status: InvoiceStatus): string {
    switch (status) {
      case InvoiceStatus.Open:
        return "Unpaid";
      case InvoiceStatus.Closed:
        return "Paid";
      default:
        return status;
    }
  }
}

export class InvoiceSummary {
  referenceNumber: string;
  date: Date;
  dueDate: Date;
  billingPeriod: string;
  status: InvoiceStatus;
  description: string;
  amount: number;
  balance: number;

  constructor(params?: any) {
    if (params) {
      this.referenceNumber = params.referenceNumber;
      this.date = new Date(params.date);
      this.dueDate = new Date(params.dueDate);
      this.billingPeriod = params.billingPeriod;
      this.status = params.status;
      this.description = params.description;
      this.amount = params.amount;
      this.balance = params.balance;
    }
  }
}

export class Customer {
  customerName: string;
  status: string;
  terms: string;
  billingContact: {
    displayName: string;
    email: string;
    phone1: string;
  };

  constructor(params?: any) {
    if (params) {
      this.customerName = params.customerName;
      this.status = params.status;
      this.terms = params.terms;
      this.billingContact = params.billingContact;
    }
  }
}

export class InvoiceLine {
  extendedPrice: number;
  qty: number;
  unitPrice: number;
  transactionDescription: string;

  constructor(params?: any) {
    if (params) {
      this.extendedPrice = params.extendedPrice;
      this.qty = params.qty;
      this.unitPrice = params.unitPrice;
      this.transactionDescription = params.transactionDescription;
    }
  }
}

export class InvoiceDetails {
  amount: number;
  balance: number;
  createdDateTime: Date;
  dueDate: Date;
  date: Date;
  referenceNbr: string;
  status: InvoiceStatus;
  details: InvoiceLine[];

  constructor(params?: any) {
    this.amount = params.amount;
    this.balance = params.balance;
    this.createdDateTime = new Date(params.amount);
    this.dueDate = new Date(params.amount);
    this.date = new Date(params.date);
    this.referenceNbr = params.referenceNbr;
    this.status = params.status;
    this.details = params.details;
  }
}

enum PaymentMethodType {
  Card = "card",
}

export class PaymentMethod {
  id: string;
  name: string;
  category: string;
  type: PaymentMethodType;
  description: string;
  createdDate: Date;
  autoPayEnabled: boolean;

  constructor(params?: any) {
    if (params) {
      this.id = params.id;
      this.type = params.type;
      this.name = params.name;
      this.category = params.category;
      this.description = params.description;
      this.createdDate = new Date(params.createdDate);
      this.autoPayEnabled = params.autoPayEnabled;
    }
  }
}

export class InitializeSetupIntent {
  clientSecret: string;
  constructor(params?: any) {
    if (params) {
      this.clientSecret = params.clientSecret;
    }
  }
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { WinBoxPropType } from "react-winbox";

export enum WindowType {
  DeveloperToolsRegressionTest,
}

export enum WindowState {
  Open,
  Closed,
}

export interface WindowConfiguration {
  id: string;
  title: string;
  type: WindowType;
  state: WindowState;
  payload: any;
  windowState: Partial<WinBoxPropType>;
}

const initialState: { windows: WindowConfiguration[] } = {
  windows: [],
};

export const windowsState = createSlice({
  name: "windows",
  initialState,
  reducers: {
    openRegressionTest: (
      state,
      action: PayloadAction<{ id: string; title: string }>
    ) => {
      // If the window is already added, then just open it
      const idx = state.windows.findIndex(
        (window) => window.id === action.payload.id
      );
      if (idx >= 0) {
        state.windows[idx].state = WindowState.Open;
      } else {
        state.windows.push(
          newWindow(
            state.windows.length,
            WindowType.DeveloperToolsRegressionTest,
            action.payload.id,
            action.payload.title,
            action.payload
          )
        );
      }
    },
    updateWindowState: (
      state,
      action: PayloadAction<{
        id: string;
        windowState: Partial<WinBoxPropType>;
      }>
    ) => {
      const idx = state.windows.findIndex(
        (window) => window.id === action.payload.id
      );
      if (idx >= 0) {
        state.windows[idx].windowState = {
          ...state.windows[idx].windowState,
          ...action.payload.windowState,
        };
      }
    },
    focusWindow: (state, action: PayloadAction<{ id: string }>) => {
      const idx = state.windows.findIndex(
        (window) => window.id === action.payload.id
      );
      if (idx >= 0) {
        // state.windows[idx].;
      }
    },
    closeWindow: (state, action: PayloadAction<{ id: string }>) => {
      const idx = state.windows.findIndex(
        (window) => window.id === action.payload.id
      );
      if (idx >= 0) {
        state.windows.splice(idx, 1);
      }
    },
  },
});

function newWindow(numWindows, type, id, title, payload): WindowConfiguration {
  return {
    state: WindowState.Open,
    id,
    title,
    type,
    payload: payload,
    windowState: {
      title: title,
      noFull: true,
      splitscreen: true,
      x: 100 + numWindows * 40,
      y: 100 + numWindows * 40,
      left: 10,
      top: 10,
      right: 10,
      bottom: 10,
      width: 700,
      height: 600,
    },
  };
}

export const { openRegressionTest, closeWindow, updateWindowState } =
  windowsState.actions;
export const selectWindows = (state: any) => state.windows;
export default persistReducer(
  {
    key: "windows",
    storage: storage,
  },
  windowsState.reducer
);

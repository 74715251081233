import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { isFunction } from "lodash";

/**
 * Setter represents a type that can be passed to the setter function returned
 * from this hook. It can either accept a value to set, or a callback function
 * with access to the current value.
 *
 * @example
 * setter(true)
 * setter(current => !current)
 */
type Setter<T> = T | ((current: T) => T);

/**
 * This custom hook provides an API for saving and retrieving values to the query
 * parameters. It can be used *almost* as a drop in replacement for the useState
 * hook, but does require you to name the query parameter key for your values.
 *
 * @example
 * const [counter, setCounter] = useQueryParams("myCounter", 0)
 *
 * useEffect(() => {
 *   setCounter(current => current + 1)
 * }, [])
 *
 * <span>{counter}</span>
 *
 * @param {string} name - The name of the query parameter. This will uniquely
 * identify the key of the value in the query parameters.
 * @param {T} initialValue - The initial value of the query parameter.
 */
export function useQueryParams<T>(
  name: string,
  initialValue?: T
): [T, (setter: Setter<T>) => void] {
  const encode = (value: T) => {
    return JSON.stringify(value);
  };
  const decode = (value: string): T => {
    return JSON.parse(value);
  };

  const [params, setParams] = useSearchParams();
  const [value, setValue] = useState(decode(params.get(name)) ?? initialValue);

  useEffect(() => {
    // If we have an initial value and no value in the query params, then set the
    // state to match the initial value.
    if (initialValue != null && !params.get(name)) {
      setValue(initialValue);
    }

    // If we have a value in the query params, then set the state to the value of
    // the query params.
    let fromParams = params.get(name);
    if (fromParams) {
      let value = decode(fromParams);
      setValue(value);
    }
  }, []);

  // Params -> State
  useEffect(() => {
    let fromParams = params.get(name);
    if (fromParams) {
      setValue(decode(fromParams));
    }
  }, [params]);

  const updateParamsFromState = useCallback(() => {
    if (encode(value) != params.get(name)) {
      params.set(name, encode(value));
      setParams(params, {
        replace: true,
      });
    }
  }, [params, value]);

  // State -> Params
  useEffect(() => {
    updateParamsFromState();
  }, [value]);
  return [value, setValue];
}

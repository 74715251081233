import { EntityWithAncestors } from "../api/types/entity";
import {
  Breadcrumbs,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { Business } from "@mui/icons-material";

interface EntitySearchResultMenuItemProps {
  entity: EntityWithAncestors;
  onClick?: any;
}

/**
 * This component displays a menu item for an EntityWithAncestorsInline and
 * is normally used in entity selection menus or search results.
 * @param {EntityWithAncestors} entity
 * @param onClick
 * @constructor
 */
export const EntitySearchResultMenuItem = ({
  entity,
  ...rest
}: EntitySearchResultMenuItemProps) => {
  return (
    <MenuItem {...rest}>
      <ListItemIcon>
        <Business />
      </ListItemIcon>
      <ListItemText>
        {entity.name}
        <Breadcrumbs separator="›">
          {entity.ancestors.map((a) => (
            <Link
              key={a.entityKey}
              underline="none"
              sx={{ fontSize: "12px" }}
              color="inherit"
            >
              {a.name}
            </Link>
          ))}
        </Breadcrumbs>
      </ListItemText>
    </MenuItem>
  );
};

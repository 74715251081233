import { TimeSeriesCounter } from "../../api/entity";
import * as echarts from "echarts/core";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { BarChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  AxisPointerComponent,
  LegendPlainComponent,
} from "echarts/components";
import { SVGRenderer } from "echarts/renderers";
echarts.use([
  BarChart,
  GridComponent,
  TooltipComponent,
  AxisPointerComponent,
  LegendPlainComponent,
  SVGRenderer,
]);

type BillableDeviceTimeSeriesParams = {
  data: Array<TimeSeriesCounter>;
};

export const BillableDeviceTimeSeries = (
  params: BillableDeviceTimeSeriesParams
) => {
  return (
    <ReactEChartsCore
      echarts={echarts}
      style={{ height: 200 }}
      option={{
        height: 180,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        grid: {
          top: "5%",
          left: "3%",
          right: "4%",
          bottom: "0%",
          containLabel: true,
          show: false,
        },
        xAxis: {
          type: "category",
          data: params.data.map((d) =>
            d.timestamp.toLocaleString("en", { month: "short" })
          ),
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
        },
        series: [
          {
            data: params.data.map((d) => d.count),
            type: "bar",
            itemStyle: {
              color: "#3182bd",
            },
          },
        ],
      }}
    />
  );
};

import { FieldErrors, Path, UseFormRegister } from "react-hook-form";
import { get } from "lodash";

export function formatCurrency(price: number | null): string {
  if (price == null) {
    return null;
  }
  return price.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

export function getFieldErrors(errors: FieldErrors, path: string) {
  // Handle validation errors on array fields and (for now) just use the first error
  let value = get(errors, path);
  if (Array.isArray(value) && value.length > 0) {
    value = value.find((v) => !!v);
  }
  return {
    error: !!value,
    helperText: <span>value?.message</span>,
  };
}

/**
 * Wrapper around the react-hook-form's register function that registers a form element
 * and spreads the helperText and error properties onto the element.
 * @param path
 * @param register
 * @param errors
 */
export function registerWithErrors<T>(
  path: Path<T>,
  register: UseFormRegister<T>,
  errors: FieldErrors
) {
  return {
    ...register(path),
    ...getFieldErrors(errors, path),
  };
}

/**
 * Helper function to get only the dirty values from a react-hook-form. See this Github
 * discussion for more information https://github.com/react-hook-form/react-hook-form/discussions/1991.
 * @param dirtyFields
 * @param allValues
 */
export function dirtyValues(
  dirtyFields: object | boolean,
  allValues: object
): object {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
  // "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;
  // Here, we have an object
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      dirtyValues(dirtyFields[key], allValues[key]),
    ])
  );
}

/**
 * Helper function to determine if the current window is in an iframe.
 */
export function isInFrame() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function toBoolean(value: any): boolean {
  switch (value) {
    case "true":
      return true;
    case "false":
      return false;
  }
}

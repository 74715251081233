import { Box } from "@mui/material";
import { EntityBreadcrumb } from "./EntityBreadcrumb";

/**
 * Wrapper around EntityBreadcrumb that provides the correct spacing to put the
 * breadcrumb into the page header.
 * @constructor
 */
export const EntityBreadcrumbPageHeader = () => {
  return (
    <Box p={0.7}>
      <EntityBreadcrumb />
    </Box>
  );
};

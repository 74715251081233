import { fetchBase } from "./base";
import { Entity, EntityWithAncestors } from "./types/entity";

export const getEntityWithAncestors = (
  entityKey: string
): Promise<EntityWithAncestors> => {
  return fetchBase(`/entity/${entityKey}?includeAncestors=true`, {
    method: "GET",
  }).then((res) => new EntityWithAncestors(res));
};

export const searchEntities = (
  term: string
): Promise<Array<EntityWithAncestors>> => {
  return fetchBase(`/entity/search?term=${term}`, {
    method: "GET",
  }).then((res: any[]) => (res ?? []).map((r) => new EntityWithAncestors(r)));
};

export const getEntityChildren = (entityKey: string): Promise<Entity[]> => {
  return fetchBase(`/entity/${entityKey}/children`, {
    method: "GET",
  }).then((res: any[]) => (res ?? []).map((r) => new Entity(r)));
};

export type ReportingDevices = {
  total: number;
  reporting: number;
  billable: number;
};

export const getEntityReportingDevices = (
  entityKey: string
): Promise<ReportingDevices> => {
  return fetchBase(`/entity/${entityKey}/devices/reportingCount`, {
    method: "GET",
  }).then((res) => <ReportingDevices>res);
};

export class TimeSeriesCounter {
  timestamp: Date;
  count: number;

  constructor(params: any) {
    if (params) {
      this.timestamp = new Date(params.timestamp);
      this.count = params.count;
    }
  }
}

export const getEntityBillableDevicesOverTime = (
  entityKey: string
): Promise<Array<TimeSeriesCounter>> => {
  return fetchBase(`/entity/${entityKey}/devices/billableTimeSeries`, {
    method: "GET",
  }).then((res: any[]) => (res ?? []).map((r) => new TimeSeriesCounter(r)));
};

import { BinnedValue } from "../../api/install";
import { useMemo } from "react";
import * as echarts from "echarts/core";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { BarChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  AxisPointerComponent,
  LegendPlainComponent,
} from "echarts/components";
import { SVGRenderer } from "echarts/renderers";
echarts.use([
  BarChart,
  GridComponent,
  TooltipComponent,
  AxisPointerComponent,
  LegendPlainComponent,
  SVGRenderer,
]);

type ReportingInstallsBinnedParams = {
  data: Array<BinnedValue<string, number>>;
};

export const ReportingInstallsBinned = (
  params: ReportingInstallsBinnedParams
) => {
  const excludeZeroFormatter = (params): string =>
    params.value === 0 ? "" : params.value.toString();

  const colors = [
    "#6edd9e",
    "rgba(250, 128, 55, 0.6)",
    "rgba(250, 128, 55, 0.9)",
    "rgba(255, 116, 116, 1)",
  ];
  const series = useMemo(
    () =>
      params.data.map((d, i) => {
        return {
          name: d.bin,
          type: "bar",
          stack: "total",
          itemStyle: {
            color: colors[i],
          },
          label: {
            show: true,
            formatter: excludeZeroFormatter,
          },
          emphasis: {
            focus: "series",
          },
          data: [d.value],
        };
      }),
    [params]
  );
  const options = useMemo(
    () => ({
      tooltip: {
        trigger: "axis",
        axisPointer: {
          // Use axis to trigger tooltip
          type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        },
        confine: true,
      },
      legend: {
        data: params.data.map((d) => d.bin),
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
      },
      yAxis: {
        type: "category",
        data: [""],
      },
      series,
    }),
    [series]
  );

  return (
    <ReactEChartsCore
      echarts={echarts}
      style={{ height: 120 }}
      option={options}
      lazyUpdate={true}
    />
  );
};

import { useNavigate } from "react-router-dom";
import { Sidebar } from "./Sidebar";
import { Navbar } from "../Navbar";
import { Box, Divider, Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../store/store";
import { selectAuth } from "../../store/auth";
import { PageHeader, PageHeaderActions } from "./PageHeader";
import "./SecuredContainer.scss";
import { PageFooterActions } from "./PageFooter";
import Cookies from "js-cookie";
import { useInitUser } from "../../hooks/auth";
import { useToggle } from "../../hooks/utilities";
import { datadogRum } from "@datadog/browser-rum";
import { selectDefaultEntity } from "../../store/entity";

/**
 * The secured container ensures that a user is logged in, and if not, the user
 * is redirected to the login page.
 * @constructor
 */
export const SecuredContainer = ({ children }) => {
  const navigate = useNavigate();
  const authState = useAppSelector(selectAuth);
  const entity = useAppSelector(selectDefaultEntity);
  const initUser = useInitUser();
  const [sidebarOpen, toggleSidebar] = useToggle(false, "sidebar");

  useEffect(() => {
    if (!authState || !authState.authenticated) {
      const cookie = Cookies.get("nxt-token");
      if (!cookie) {
        navigate("/auth/login");
      }
      initUser().then();
    }
  }, [authState, initUser]);

  // Set datadog user information
  useEffect(() => {
    if (authState.authenticated && entity) {
      datadogRum.setUser({
        id: authState.userKey,
        name: `${authState.firstName} ${authState.lastName}`,
        email: authState.email,
        entity: entity.name,
        entityKey: entity._id,
        evaluation: entity.expiresOn != null,
      });
    }
  }, [authState, entity]);

  const offsetEm = 4;

  return (
    <>
      <Grid className="full-height" container>
        <Grid item>
          <Navbar onOpenSidebar={toggleSidebar} />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex" }} className="full-height">
            <Sidebar open={sidebarOpen} />
            <Box
              // sx={{ marginLeft: "240px", marginTop: "4.8em", height: "100%" }}
              sx={{ flexGrow: 1, paddingTop: `${offsetEm}em` }}
              height={`calc(100% - ${offsetEm}em)`}
            >
              <div className="bg-white h-[50px] border-b border-gray-200 flex items-center space-x-4">
                <PageHeader />
                <PageHeaderActions />
              </div>
              {children}
              <PageFooterActions />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

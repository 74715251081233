import { Install } from "../../api/install";
import {
  Box,
  Divider,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { useQueryParams } from "../../hooks/params";
import { StatCard } from "../StatCard";
import { ReportingDevicesBar } from "../viz/ReportingDevicesBar";
import { InstallReportingDevicesWidget } from "./InstallReportingDevicesWidget";
import { InstallReportingDevicesCategoricalDistribution } from "./InstallReportingDevicesCategoricalDistribution";

type InstallDetailsProps = {
  install: Install;
};

export const InstallDetailsSummaryTab = ({ install }: InstallDetailsProps) => {
  const reporting = install.reportingDeviceCount;
  const notReporting = install.totalDeviceCount - install.reportingDeviceCount;
  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper>
            <Box p={2}>
              <Table
                size="small"
                sx={{
                  "& .MuiTableCell-sizeSmall": {
                    padding: 0,
                  },
                }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>OS</strong>
                    </TableCell>
                    <TableCell>{install.os}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>OS Version</strong>
                    </TableCell>
                    <TableCell>{install.osVersion}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>OS Family</strong>
                    </TableCell>
                    <TableCell>{install.osFamily}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Laptop</strong>
                    </TableCell>
                    <TableCell>{install.hasBattery ? "Yes" : "No"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Version</strong>
                    </TableCell>
                    <TableCell>
                      <Table
                        size="small"
                        sx={{
                          "& .MuiTableCell-sizeSmall": {
                            border: 0,
                          },
                        }}
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell>Install</TableCell>
                            <TableCell>{install.version}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Chromium</TableCell>
                            <TableCell>
                              {install.moduleVersions.chromium}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Watcher</TableCell>
                            <TableCell>
                              {install.moduleVersions.watcher}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>PMonitor</TableCell>
                            <TableCell>
                              {install.moduleVersions.ptpmonitor}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <StatCard header="Scanned Network Addresses">
            <Typography variant="h1" fontWeight="bolder">
              {install.trackedIpAddressCount.toLocaleString("en-US")}
            </Typography>
          </StatCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <StatCard header="Reporting Devices">
            <Grid container>
              <Grid item xs={3}>
                <Typography variant="subtitle1" fontWeight="bolder">
                  {reporting} / {reporting + notReporting}
                </Typography>
              </Grid>
              <Grid item xs={9} pt={1}>
                <InstallReportingDevicesCategoricalDistribution
                  reporting={reporting}
                  notReporting={notReporting}
                />
              </Grid>
            </Grid>
          </StatCard>
        </Grid>
      </Grid>
    </Box>
  );
};

import { Skeleton, SkeletonProps } from "@mui/material";
import { ReactNode } from "react";

type LazySkeletonProps = SkeletonProps & {
  children: ReactNode | JSX.Element;
  loading: boolean;
};

export const LazySkeleton = ({
  loading,
  children,
  ...rest
}: LazySkeletonProps) => {
  if (loading) {
    return <Skeleton {...rest} />;
  } else {
    return <>{children}</>;
  }
};

import { fetchBase } from "./base";

export interface LoginParams {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
}

export const login = (params: LoginParams): Promise<string> => {
  return fetchBase<LoginResponse>("/auth/login", {
    method: "POST",
    body: JSON.stringify(params),
  }).then((res) => {
    localStorage.setItem("nxt-token", res.token);
    return res.token;
  });
};

export const check = (): Promise<any> => {
  return fetchBase<any>("/auth/check");
};

import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

/**
 * Used for persisting the auth state to local storage
 */
const authPersistConfig = {
  key: "auth",
  storage: storage,
};

interface AuthState {
  permissions: string[];
  userKey: string;
  firstName: string;
  lastName: string;
  email: string;
  authenticated: boolean;
}

const initialState: AuthState = {
  authenticated: false,
  userKey: "",
  firstName: "",
  lastName: "",
  email: "",
  permissions: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.authenticated = action.payload._id && action.payload._id.length > 0;
      state.userKey = action.payload._id;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.permissions = action.payload.permissions.getPermissions();
    },
    logout: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const selectAuth = (state: any) => state.auth;

export const { login, logout } = authSlice.actions;

export default persistReducer(authPersistConfig, authSlice.reducer);

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../locales/en/translation.json";
import es_mx from "../locales/es-mx/translation.json";
import pt_br from "../locales/pt-br/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      ...resource("en", en),
      ...resource("es-MX", es_mx),
      ...resource("pt-BR", pt_br),
    },
    detection: {
      lookupLocalStorage: "lang",
    },
  })
  .then(() => {
    console.log("i18n initialized");
  });

function resource(code: string, resources: any) {
  return {
    [code]: { translation: resources },
  };
}

export default i18n;

import { useContext, useEffect } from "react";
import { PageHeaderContext } from "../state/page-header";

/**
 * This custom hook sets the page header using a page header context
 * @param {string} initialValue - The name of the page
 */
export const usePageHeader = (
  initialValue?: string
): [string, (string) => void] => {
  const { header, setHeader } = useContext(PageHeaderContext);
  useEffect(() => {
    setHeader(header);
  }, [header]);
  useEffect(() => {
    if (initialValue) setHeader(initialValue);
  }, []);
  return [header, setHeader];
};

export const wildcard = "*";

export enum Action {
  Create = "create",
  Delete = "delete",
  Update = "update",
  Read = "read",
  List = "list",
}

// A permission refers to a specific action that a user is allowed to perform, or
// when converted to a permission requirement, refers to a specific permission that
// the user must have in order to perform an action.
export class Permission {
  protected readonly path: string;
  constructor(path: string) {
    this.path = path;
  }

  /**
   * Returns the permission represented as a string
   */
  public string(): string {
    return this.path;
  }

  /**
   * Returns the permission represented as a permission requirement. Permission
   * requirements are identical to permissions except they do not allow wildcards.
   * This is because when an application wants to enforce a set of permissions that
   * are required in order to do something, it needs to be specific about exactly
   * what permissions are required in order to do something. Permissions on the
   * other hand are broad and allow wildcards so that few permissions can be
   * compatible with many, different permission requirements.
   */
  public asRequirement(): PermissionRequirement {
    let permission = this.string();
    if (permission.includes(wildcard)) {
      throw new Error("permission requirement cannot contain wildcard");
    }
    return new PermissionRequirement(permission);
  }
}

// A collection of permissions. Usually the application will need to check whether
// many permissions satisfy many permission requirements. A permission group helps
// the application to check this.
export class PermissionGroup {
  protected readonly permissions: Permission[] = [];
  constructor(permissions: string[]) {
    this.permissions = permissions.map((p) => new Permission(p));
  }

  /**
   * Returns true if this permission satisfies the provided requirement.
   * @param {PermissionRequirement} requirement - The permission the application
   * is requiring the user to poses.
   */
  public satisfies(requirement: PermissionRequirement): boolean {
    for (const permission of this.permissions) {
      if (requirement.isSatisfiedBy(permission)) {
        return true;
      }
    }
    return false;
  }

  public getPermissions(): string[] {
    return this.permissions.map((p) => p.string());
  }
}

// A collection of permission requirements. Usually the application will need to
// check whether many permissions satisfy many permission requirements. A permission
// requirement group helps the application to check this.
export class PermissionRequirementGroup {
  protected readonly requirements: PermissionRequirement[] = [];
  constructor(requirements: PermissionRequirement[]) {
    this.requirements = requirements;
  }

  /**
   * Returns true if all the permission requirements are satisfied by the provided
   * permission group.
   * @param {PermissionGroup} group - A group of permissions possessed by a user
   */
  public isSatisfiedBy(group: PermissionGroup): boolean {
    for (const requirement of this.requirements) {
      if (!group.satisfies(requirement)) {
        return false;
      }
    }
    return true;
  }
}

// A permission requirement is nothing more than a permission with the exception
// that permission requirements cannot contain wildcards. This is because when
// an application wants to enforce a set of permissions that are required in
// order to do something, it needs to be specific about exactly what permissions
// are required in order to do something. Permissions on the other hand are broad
// and allow wildcards so that few permissions can be compatible with many,
// different permission requirements.
export class PermissionRequirement extends Permission {
  /**
   * Returns true if the provided permission satisfies this permission requirement
   * @param {Permission} permission - The permission that we want to check
   */
  public isSatisfiedBy(permission: Permission): boolean {
    let rParts = this.path.split(".");
    let pParts = permission.string().split(".");
    for (let i = 0; i < pParts.length; i++) {
      if (pParts[i] == wildcard) {
        return true;
      } else if (pParts[i] == rParts[i]) {
        continue;
      } else {
        return false;
      }
    }
    return true;
  }
}

/**
 * Returns a permission requirement group given a list of permissions. None of
 * the permissions are allowed to be wildcard permissions.
 * @param {Permission[]} permissions
 */
export function requiresPermissions(
  ...permissions: Permission[]
): PermissionRequirementGroup {
  return new PermissionRequirementGroup(
    permissions.map((p) => p.asRequirement())
  );
}

/**
 * Returns a permission group given a list of permissions.
 * @param permissions
 */
export function parsePermissions(...permissions: string[]): PermissionGroup {
  return new PermissionGroup(permissions);
}

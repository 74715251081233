import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  globalIsLoading: false,
};

export const loadingState = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setGlobalLoading: (state, action) => {
      state.globalIsLoading = action.payload;
    },
  },
});

export const { setGlobalLoading } = loadingState.actions;
export const selectGlobalIsLoading = (state: any) =>
  state.loading.globalIsLoading;
export default loadingState.reducer;

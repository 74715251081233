import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  getEntityBillableDevicesOverTime,
  getEntityChildren,
  getEntityReportingDevices,
  getEntityWithAncestors,
} from "../api/entity";
import { setCurrentEntity } from "../store/entity";
import { setGlobalLoading } from "../store/loading";
import { useEntityKeyParam } from "./navigate";
import { useQuery } from "react-query";
import { getEntityUsers } from "../api/user";

type UseSetCurrentEntityParams = {
  updateParams?: boolean;
  resetParams?: boolean;
};

/**
 * This hook returns a setter function that can set the current entity. The function
 * handles the requests to the API, the updating of the Redux store, and the setting
 * of query parameters (optionally).
 */
export const useSetCurrentEntity = () => {
  const dispatch = useDispatch();
  const [_, setEntityKeyParam] = useEntityKeyParam();
  const { enqueueSnackbar } = useSnackbar();
  return async (entityKey: string, params?: UseSetCurrentEntityParams) => {
    const updateParams = params?.updateParams ?? true;
    dispatch(setGlobalLoading(true));
    try {
      const entity = await getEntityWithAncestors(entityKey);
      if (updateParams) {
        setEntityKeyParam(entity._id, params?.resetParams);
      }
      dispatch(setCurrentEntity(entity.serialize()));
      dispatch(setGlobalLoading(false));
    } catch (e) {
      dispatch(setGlobalLoading(false));
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };
};

export const useGetEntityWithAncestors = (entityKey: string) => {
  return useQuery([entityKey], () => getEntityWithAncestors(entityKey));
};

export const useGetEntityChildren = (entityKey: string) => {
  return useQuery(
    ["entity", entityKey, "children"],
    () => getEntityChildren(entityKey),
    { keepPreviousData: true, refetchOnMount: false, retryOnMount: false }
  );
};

export const useGetEntityUsers = (entityKey: string) => {
  return useQuery(["entity", entityKey, "users"], () =>
    getEntityUsers(entityKey)
  );
};

export const useGetEntityReportingDevices = (entityKey: string) => {
  return useQuery(["entity", entityKey, "reportingDevices"], () => {
    return getEntityReportingDevices(entityKey);
  });
};

export const useGetEntityBillableDevicesOverTime = (entityKey: string) => {
  return useQuery(["entity", entityKey, "billableDevicesOverTime"], () => {
    return getEntityBillableDevicesOverTime(entityKey).then((v) =>
      v.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime())
    );
  });
};

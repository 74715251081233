import {
  JobStatuses,
  RegressionTest,
  useGetRegressionTest,
} from "../../api/developer";
import { JobStatusCategoricalDistribution } from "../JobStatusCategoricalDistribution";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { CircularProgress, Grid } from "@mui/material";

type Props = {
  regressionTest: RegressionTest;
};

export const RegressionTestJobStatusCategoricalDistribution = ({
  regressionTest,
}: Props) => {
  const [statuses, setStatuses] = useState(regressionTest.jobStatuses);
  const { data, refetch } = useGetRegressionTest(
    regressionTest.id,
    "RegressionTestJobStatusCategoricalDistribution"
  );

  // We can stop polling when all the jobs are either completed or failed
  const isDone = useMemo(
    () =>
      statuses.sent === 0 && statuses.received === 0 && statuses.running === 0,
    [statuses]
  );

  const donePolling = useMemo(() => {
    const created = moment(regressionTest.createdDate);
    const before = moment().subtract(1, "hour");
    return created.isBefore(before) || isDone;
  }, [isDone]);

  // As long as all the jobs aren't done, keep polling
  useEffect(() => {
    if (donePolling) return;
    const interval = setInterval(() => refetch().then(), 2000);
    return () => clearInterval(interval);
  }, [regressionTest, donePolling]);

  // When the data changes, update the status state
  useEffect(() => {
    if (data) setStatuses(data.jobStatuses);
  }, [data]);

  return (
    <Grid container>
      {!donePolling && (
        <Grid item xs="auto" pr={2}>
          <CircularProgress thickness={8} size={10} variant="indeterminate" />
        </Grid>
      )}

      <Grid item xs pt={!donePolling ? 0.6 : 0}>
        <JobStatusCategoricalDistribution {...statuses} />
      </Grid>
    </Grid>
  );
};

import { Result } from "./RegressionTestDetailsResultsTab";
import { RegressionTestResultHeatmap } from "./RegressionTestResultHeatmap";

type RegressionTestInsightsProps = {
  results: Result[];
};

export const RegressionTestInsights = ({
  results,
}: RegressionTestInsightsProps) => {
  return <RegressionTestResultHeatmap results={results} />;
};

import { BaseModel } from "./base";
import { PermissionGroup } from "../../logic/auth/permissionsCore";
import { Entity } from "./entity";

export class User extends BaseModel {
  userKey: string;
  firstName: string;
  lastName: string;
  email: string;

  constructor(props) {
    super(props);

    if (props) {
      this.userKey = this._id;
      this.firstName = props.firstName;
      this.lastName = props.lastName;
      this.email = props.email;
    }
  }
}

export class UserWithRolesEntityAndPermissions extends User {
  permissions: PermissionGroup;
  entity: Entity;

  constructor(props) {
    super(props);
    this.permissions = new PermissionGroup(props.permissions);
    this.entity = new Entity(props.entity);
  }
}

// Parameter is the boolean, with default "false" value
import { useCallback, useState } from "react";

export const useToggle = (
  initialState = false,
  localStorageKey = ""
): [boolean, () => void] => {
  if (localStorageKey) {
    let value = getLocalBoolean(localStorageKey);
    if (value) initialState = value;
  }
  const [state, setState] = useState(initialState);
  const toggle = useCallback(
    () =>
      setState((state) => {
        setLocalBoolean(localStorageKey, !state);
        return !state;
      }),
    []
  );
  return [state, toggle];
};

const getLocalBoolean = (key: string): boolean | null => {
  return localStorage.getItem(key) === "true";
};

const setLocalBoolean = (key: string, value: boolean) => {
  return localStorage.setItem(key, value.toString());
};

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

import { StyledDataGrid, StyledDataGridProps } from "../tables/StyledDataGrid";
import { useMemo } from "react";
import { GridColumns } from "@mui/x-data-grid-pro";
import { InvoiceLine } from "../../api/types/billing";
import { useGetInvoiceDetails } from "../../hooks/billing";
import { useAppSelector } from "../../store/store";
import { selectCurrentEntity } from "../../store/entity";
import {
  Box,
  Divider,
  Grid,
  Paper,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { BackgroundColor } from "../../hooks/styles";
import { LazySkeleton } from "../LazySkeleton";
import { formatCurrency } from "../../helpers";

export const InvoicePage = ({ referenceNbr }) => {
  const entity = useAppSelector(selectCurrentEntity);
  const { data, isFetching } = useGetInvoiceDetails(
    entity?.entityKey,
    referenceNbr
  );
  return (
    <Grid container>
      <Grid item xs={12} bgcolor={BackgroundColor.Gray}>
        <Grid container spacing={2} p={2}>
          <Grid item xs={12} md={6} lg={4}>
            <Paper>
              <Box>
                <Table size="small">
                  <TableRow>
                    <TableCell>
                      <Typography variant="body2" fontWeight={500}>
                        Customer Name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <LazySkeleton loading={isFetching}>
                        {data?.customer.customerName}
                      </LazySkeleton>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant="body2" fontWeight={500}>
                        Billing Contact Name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <LazySkeleton loading={isFetching}>
                        {data?.customer.billingContact.displayName}
                      </LazySkeleton>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant="body2" fontWeight={500}>
                        Billing Contact Email
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <LazySkeleton loading={isFetching}>
                        {data?.customer.billingContact.email}
                      </LazySkeleton>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant="body2" fontWeight={500}>
                        Terms
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <LazySkeleton loading={isFetching}>
                        {data?.customer.terms}
                      </LazySkeleton>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant="body2" fontWeight={500}>
                        Invoice Amount
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <LazySkeleton loading={isFetching}>
                        {formatCurrency(data?.invoice.amount)}
                      </LazySkeleton>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant="body2" fontWeight={500}>
                        Invoice Balance
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <LazySkeleton loading={isFetching}>
                        {formatCurrency(data?.invoice.balance)}
                      </LazySkeleton>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant="body2" fontWeight={500}>
                        Paid
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <LazySkeleton loading={isFetching}>
                        {formatCurrency(
                          data?.invoice.amount - data?.invoice.balance
                        )}
                      </LazySkeleton>
                    </TableCell>
                  </TableRow>
                </Table>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
        <InvoiceLineItemTable
          loading={isFetching}
          data={data?.invoice.details}
        />
      </Grid>
    </Grid>
  );
};

const HeaderTable = ({ data, loading }) => {
  return data.map((d) => {
    return (
      <TableRow>
        <TableCell>
          <Typography variant="body2" fontWeight={500}>
            {d[0]}
          </Typography>
        </TableCell>
        <TableCell>
          <LazySkeleton loading={loading}>{d[1]}</LazySkeleton>
        </TableCell>
      </TableRow>
    );
  });
};

type InvoiceLineItemTableParams = Omit<
  StyledDataGridProps,
  "columns" | "rows"
> & {
  data: Array<InvoiceLine>;
};

const InvoiceLineItemTable = ({
  data,
  ...rest
}: InvoiceLineItemTableParams) => {
  const columns = useMemo(
    (): GridColumns => [
      {
        headerName: "Description",
        field: "transactionDescription",
        flex: 1,
      },
      {
        headerName: "Unit Price",
        field: "unitPrice",
        flex: 1,
        valueFormatter: (params) =>
          params.value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }),
      },
      {
        headerName: "Quantity",
        field: "qty",
        flex: 1,
      },
      {
        headerName: "Amount",
        field: "extendedPrice",
        flex: 1,
        valueFormatter: (params) =>
          params.value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }),
      },
    ],
    []
  );
  return (
    <StyledDataGrid
      {...rest}
      disableSelectionOnClick={true}
      columns={columns}
      rows={data ?? []}
      bordered={false}
    />
  );
};

import { Box, Card, CardContent, Grid, Paper, Typography } from "@mui/material";

type StatCardProps = {
  header: string;
  children: JSX.Element;
};

export const StatCard = ({ header, children }: StatCardProps) => {
  return (
    <Card>
      <CardContent>
        <Typography color="text.secondary" gutterBottom>
          {header}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
};

import { Button } from "@mui/material";
import { useState } from "react";

export const IdQuickCopyButton = ({ id }) => {
  const shortId = id?.slice(-6);
  const [value, setValue] = useState(shortId);
  const handleCopy = () => {
    navigator.clipboard.writeText(id).then();
    setValue("Copied");
    setTimeout(() => setValue(shortId), 1000);
  };
  return (
    <Button size="small" onClick={handleCopy}>
      {value}
    </Button>
  );
};

import { BaseModel } from "./base";

export class Entity extends BaseModel {
  entityKey: string;
  name: string;
  parentEntityKey: string;
  evaluation: boolean;
  expiresOn: Date;
  isDealer: boolean = false;
  genealogy: string[];

  constructor(props) {
    super(props);
    if (props) {
      this.entityKey = this._id;
      this.name = props.name;
      this.parentEntityKey = props.parentEntityKey;
      this.evaluation = props.evaluation;
      this.expiresOn = props.expiresOn;
      this.isDealer = props.isDealer ?? this.isDealer;
      this.genealogy = props.genealogy;
    }
  }

  public serialize(): Object {
    return JSON.parse(JSON.stringify(this));
  }
}

export class EntityWithAncestors extends Entity {
  public declare ancestors: Entity[];

  constructor(props) {
    super(props);
    this.ancestors = props.ancestors
      ? props.ancestors.map((a) => new Entity(a))
      : [];
  }

  public hierarchy(): Entity[] {
    const entity = { ...this };
    delete entity.ancestors;
    return [...this.ancestors, entity];
  }
}

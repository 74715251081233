import { Result } from "./RegressionTestDetailsResultsTab";

/**
 * Converts a result object into an array of objects with the path, control value, and variable value.
 * @param result
 */
export function compareResults(
  result: Result
): Array<{ path: string; control: any; variable: any }> {
  const control = flattenKeys(JSON.parse(result.controlResult));
  const variable = flattenKeys(JSON.parse(result.variableResult));
  const allKeys = result.valuesAdded
    .concat(result.valuesRemoved)
    .concat(result.valuesChanged);
  return allKeys.map((path) => ({
    path,
    control: control[path],
    variable: variable[path],
  }));
}

/**
 * Returns an Object of key value pairs. The keys are full paths to the values and the values are the values
 * @param result
 */
export function getValuesObject(
  result: Result
): Array<{ path: string; value: any }> {
  const object = flattenKeys(JSON.parse(result.variableResult));
  return Object.keys(object).map((v) => ({ path: v, value: object[v] }));
}

export function flattenKeys(object, initialPathPrefix = "") {
  if (!object || typeof object !== "object") {
    return [{ [initialPathPrefix]: object }];
  }

  const prefix = initialPathPrefix
    ? Array.isArray(object)
      ? initialPathPrefix
      : `${initialPathPrefix}.`
    : "";

  return Object.keys(object)
    .filter((key) => !key.endsWith("priority"))
    .flatMap((key) =>
      flattenKeys(
        object[key],
        Array.isArray(object) ? `${prefix}[${key}]` : `${prefix}${key}`
      )
    )
    .reduce((acc, path) => Object.assign(acc, path), {});
}

export enum ColumnType {
  Numerical,
  Categorical,
}

/**
 * Returns the ColumnType based on the values. If there's more non-numerical strings than
 * numbers then we return categorical, otherwise we return numeric.
 * @param values
 */
export function valuesToColumnType(values: any[]): ColumnType {
  const stringCount = values.reduce((count, value) => {
    if (typeof value === "string" || isNaN(parseFloat(value))) {
      return count + 1;
    }
    return count;
  }, 0);
  const numericCount = values.reduce((count, value) => {
    if (typeof value === "number" || !isNaN(parseFloat(value))) {
      return count + 1;
    }
    return count;
  }, 0);
  return stringCount > numericCount
    ? ColumnType.Categorical
    : ColumnType.Numerical;
}

export interface ColumnValues {
  /**
   * The name of the column
   */
  name: string;
  type: ColumnType;
  /**
   * The number of distinct values in the column
   */
  distinct: number;
  /**
   * The distribution of the values in the column
   */
  valueCounts: { value: any; count: number }[];
}

/**
 * Accepts an array of regression test results and converts them into an array of objects, one for
 * each column value that was either added, changed, or removed in the regression test results. The
 * object also contains all the values that were seen in the new changes of the regression test and
 * returns the count by value.
 * @param results
 */
export function resultsToColumns(results: Array<Result>): Array<ColumnValues> {
  const compared = results.map((result) => getValuesObject(result));

  // Get the count of distinct values of 'variable' grouped by 'path'
  const grouped = compared.reduce((acc, result) => {
    result.forEach((value) => {
      // If we haven't seen this path before, initialize it
      if (!acc.has(value.path)) {
        acc.set(value.path, []);
      }

      // Find the existing value in the array, if present
      const existingValue = acc
        .get(value.path)
        .find((v) => v.value === value.value);

      // If we have seen this path before, check if we've seen this value before
      if (existingValue) {
        existingValue.count++;
      } else {
        acc.get(value.path).push({ value: value.value, count: 1 });
      }
    });

    return acc;
  }, new Map());
  return Array.from(grouped.entries()).map(([name, valueCounts]) => ({
    name,
    valueCounts,
    type: valuesToColumnType(valueCounts.map((v) => v.value)),
    distinct: valueCounts.length,
  }));
}

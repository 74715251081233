import { useCallback, useMemo } from "react";
import "./CategoricalDistribution.scss";
import { Box, useTheme } from "@mui/material";

interface Category {
  name: string;
  color: string;
  value: number;
}

type CategoricalDistributionProps = {
  categories: Category[];
  height?: string;
};

export const CategoricalDistribution = ({
  height,
  categories,
}: CategoricalDistributionProps) => {
  const theme = useTheme();
  height = height ?? "8px";
  let distributions = useMemo(() => {
    let total = categories
      .map((c) => c.value)
      .reduce((acc, next) => (acc += next), 0);
    return categories.map((c) => ({
      ...c,
      value: c.value / total,
    }));
  }, [categories]);
  let getColor = useCallback(
    (color) => {
      switch (color) {
        case "success":
          return theme.palette.success.main;
        case "warning":
          return theme.palette.warning.main;
        case "error":
          return theme.palette.error.main;
        default:
          return color;
      }
    },
    [theme]
  );
  return (
    <Box className="progress">
      {distributions
        .filter((d) => d.value > 0)
        .map((d) => (
          <Box
            key={d.name}
            className="element"
            sx={{
              flex: d.value,
              height: height,
              backgroundColor: getColor(d.color),
            }}
          />
        ))}
    </Box>
  );
};

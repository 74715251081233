import { Action, Permission } from "./permissionsCore";

export const action = (path: Permission, action: Action): Permission =>
  join(path, new Permission(action));
export const join = (...parts: Permission[]): Permission =>
  new Permission(parts.map((p) => p.string()).join("."));
export const perm = (permission: string): Permission =>
  new Permission(permission);

export namespace Permissions {
  export namespace Install {
    export const List = new Permission("installList");
    export const Manage = new Permission("installManage");
  }
  export namespace Billing {
    export const Manage = new Permission("manageBilling");
  }
  export namespace Integrations {
    export const Manage = new Permission("manageIntegrations");
    export const List = new Permission("listIntegrations");
  }
  export const DaemonOnly = new Permission("daemonOnly");
}

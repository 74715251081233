import {
  Box,
  Divider,
  Grid,
  IconButton,
  MenuList,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import { EntitySearchResultMenuItem } from "./EntitySearchResultMenuItem";
import { EntityWithAncestors } from "../api/types/entity";
import { Close } from "@mui/icons-material";

interface GlobalSearchResultsProps {
  filter: string;
  entities: EntityWithAncestors[];
  entitiesLoading: boolean;
  onClickEntity: (EntityWithAncestors) => {};
  onClose: () => void;
}

export const GlobalSearchResults = ({
  entities,
  entitiesLoading,
  onClickEntity,
  onClose,
}: GlobalSearchResultsProps) => {
  return (
    <Paper sx={{ width: 600, position: "absolute" }}>
      <MenuList dense>
        <Grid container pb={0.6}>
          <Grid item xs pt={0.2}>
            <Typography variant="overline" pl={2}>
              <strong>Entities</strong>
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Box pr={1}>
              <IconButton size="small" color="secondary" onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Divider />
        {entitiesLoading ? (
          <Box pl={2} pr={2} pt={1}>
            <Skeleton />
          </Box>
        ) : entities.length === 0 ? (
          <Box pl={2} pr={2} pt={1}>
            <Typography variant="body2">No results found</Typography>
          </Box>
        ) : (
          entities.map((e) => (
            <EntitySearchResultMenuItem
              key={e.entityKey}
              entity={e}
              onClick={() => onClickEntity(e)}
            />
          ))
        )}
      </MenuList>
    </Paper>
  );
};

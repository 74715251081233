import { createSlice } from "@reduxjs/toolkit";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer } from "redux-persist";
import { Entity, EntityWithAncestors } from "../api/types/entity";

/**
 * Used for persisting the state to local storage
 */
const entityPersistConfig = {
  key: "entity",
  storage: storageSession,
};

const initialState: {
  defaultEntity: Entity;
  currentEntity: EntityWithAncestors;
} = {
  defaultEntity: null,
  currentEntity: null,
};

export const entitySlice = createSlice({
  name: "entity",
  initialState,
  reducers: {
    setCurrentEntity: (state, action) => {
      // If the caller does not have an ancestors array, then use the ancestors
      // array that currently exists in the store.
      state.currentEntity = {
        ...action.payload,
        ancestors: action.payload?.ancestors ?? state.currentEntity.ancestors,
      };
    },
    setDefaultEntity: (state, action) => {
      state.defaultEntity = action.payload;
    },
    unsetEntityData: (state) => {
      state.defaultEntity = initialState.defaultEntity;
      state.currentEntity = initialState.currentEntity;
    },
  },
});

export const selectCurrentEntity = (state: any): EntityWithAncestors => {
  return state?.entity?.currentEntity != null
    ? new EntityWithAncestors(state?.entity.currentEntity)
    : null;
};

export const selectDefaultEntity = (state: any): Entity => {
  return state?.entity?.defaultEntity != null
    ? new Entity(state?.entity.defaultEntity)
    : null;
};
export const { setCurrentEntity, setDefaultEntity, unsetEntityData } =
  entitySlice.actions;
export default persistReducer(entityPersistConfig, entitySlice.reducer);

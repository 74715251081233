import { Box, Portal } from "@mui/material";
import "./PageFooter.scss";

/**
 * The children elements of this component are automatically placed into the page
 * header actions section which comes immediately after the page header.
 * @param children
 * @constructor
 */
export const PageFooterActionsPortal = ({ children }) => {
  return (
    <>
      <Portal container={() => document.getElementById("page-footer-actions")}>
        <Box bgcolor="white" position="fixed" sx={{ bottom: 0, width: "100%" }}>
          {children}
        </Box>
      </Portal>
    </>
  );
};

/**
 * This is the destination for the children of the PageFooterActionsPortal component
 * @constructor
 */
export const PageFooterActions = () => {
  return <Box id="page-footer-actions" className="footer"></Box>;
};

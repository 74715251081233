import { fetchBase, getUrl, gqlQuery, Paginated } from "./base";
import { usePaginatedQuery } from "../hooks/paginate";
import { gql } from "@apollo/client";
import { useQuery, UseQueryResult } from "react-query";

interface IBinnedValue<T, U> {
  bin: T;
  value: U;
}

export class BinnedValue<T, U> {
  bin: T;
  value: U;
  constructor(params?: IBinnedValue<T, U>) {
    if (params) {
      this.bin = params.bin;
      this.value = params.value;
    }
  }
}

export const getReportingInstallsBinned = (
  entityKey: string
): Promise<Array<BinnedValue<string, number>>> => {
  const order = ["< 1 day", "1-7 days", "7-30 days", "> 30 days"];
  return fetchBase(`/entity/${entityKey}/installs/reportingBinned`, {
    method: "GET",
  }).then((res: any[]) =>
    (res ?? [])
      .map((r) => new BinnedValue<string, number>(r))
      .sort((a, b) => order.indexOf(a.bin) - order.indexOf(b.bin))
  );
};

export interface Install {
  id: string;
  machineName: string;
  os: string;
  osFamily: string;
  osVersion: string;
  version: string;
  antivirus: string;
  publicIp: string;
  privateIp: string;
  isLocal: boolean;
  hasBattery: boolean;
  reportingDeviceCount: number;
  totalDeviceCount: number;
  trackedIpAddressCount: number;
  moduleVersions: {
    chromium?: string;
    ptpmonitor?: string;
    watcher?: string;
  };
}

export const usePaginatedInstalls = (
  entityKey: string,
  includeChildren: boolean
) => {
  return usePaginatedQuery<Install>(
    ["entity", entityKey, "install"],
    async (pager) => {
      return await gqlQuery<{ installList: Array<Install> }>(
        gql`
          query installsList(
            $entityKey: ObjectId!
            $includeChildren: Boolean
            $limit: Int
            $page: Int
            $filter: Filter
            $sort: [Sort]
          ) {
            installList(
              entityKey: $entityKey
              includeChildren: $includeChildren
              limit: $limit
              page: $page
              filter: $filter
              sort: $sort
            ) {
              id
              machineName
              publicIp
              privateIp
              version
              entityName
              entityKey
              antivirus
              lastCheckIn
              status
              os
              hasBattery
              isLocal
            }
          }
        `,
        {
          entityKey,
          includeChildren,
          page: pager.page,
          limit: pager.limit,
          sort: pager.sort,
          filter: {
            items: pager.filter?.items,
            linkOperator: pager.filter?.linkOperator,
          },
        }
      ).then((res) => {
        return new Paginated({ rows: res?.installList ?? [] });
      });
    }
  );
};

export const useInstall = (
  installKey: string
): UseQueryResult<{ install: Install }> => {
  return useQuery(["install", installKey], () =>
    gqlQuery(
      gql`
        query install($installKey: ObjectId!) {
          install(installKey: $installKey) {
            id
            machineName
            publicIp
            privateIp
            version
            entityName
            entityKey
            antivirus
            lastCheckIn
            status
            os
            osFamily
            osVersion
            hasBattery
            isLocal
            #            reportingDeviceCount
            #            totalDeviceCount
            #            trackedIpAddressCount
            moduleVersions {
              chromium
              ptpmonitor
              watcher
            }
          }
        }
      `,
      {
        installKey,
      }
    )
  );
};

export const useCurrentInstallVersion = (): UseQueryResult<string> => {
  return useQuery("currentInstallVersion", () =>
    gqlQuery(
      gql`
        query {
          installCurrentVersion
        }
      `,
      {}
    ).then((res: any) => res.installCurrentVersion)
  );
};

export const getOneClickLink = (
  entityKey: string,
  entityName: string,
  os: string,
  arch: string,
  version?: string
) => {
  let path = `/install/download/one-click?key=${entityKey}&name=${entityName}&os=${os}&arch=${arch}`;
  if (version) {
    path += `&version=${version}`;
  }
  return getUrl(path);
};

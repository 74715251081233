import { Box, Portal, Skeleton, Typography } from "@mui/material";
import { useContext } from "react";
import { PageHeaderContext } from "../../state/page-header";

export const PageHeader = () => {
  const { header } = useContext(PageHeaderContext);

  return (
    <>
      {header === "" ? (
        <div className="pl-4 w-[150px]">
          {" "}
          {/* TailwindCSS classes for padding and width */}
          <Skeleton />
        </div>
      ) : (
        <Typography component="span" variant="h1" className="pl-4">
          {header}
        </Typography>
      )}
    </>
  );
};

/**
 * The children elements of this component are automatically placed into the page
 * header actions section which comes immediately after the page header.
 * @param children
 * @constructor
 */
export const PageHeaderActionsPortal = ({ children }) => {
  return (
    <>
      <Portal container={() => document.getElementById("page-header-actions")}>
        {children}
      </Portal>
    </>
  );
};

/**
 * This is the destination for the children of the PageHeaderActionsPortal component
 * @constructor
 */
export const PageHeaderActions = () => {
  return <Box id="page-header-actions"></Box>;
};

import { ReportingDevices } from "../../api/entity";
import * as echarts from "echarts/core";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { BarChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  AxisPointerComponent,
  LegendPlainComponent,
} from "echarts/components";
import { SVGRenderer } from "echarts/renderers";
echarts.use([
  BarChart,
  GridComponent,
  TooltipComponent,
  AxisPointerComponent,
  LegendPlainComponent,
  SVGRenderer,
]);

type ReportingDevicesBarParams = {
  stats: ReportingDevices;
};

export const ReportingDevicesBar = (params: ReportingDevicesBarParams) => {
  return (
    <ReactEChartsCore
      echarts={echarts}
      style={{ height: 120 }}
      option={{
        height: 75,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        legend: {
          align: "left",
          orient: "horizontal",
        },
        grid: {
          top: "30%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: [""],
        },
        series: [
          {
            name: "Billable",
            type: "bar",
            itemStyle: {
              color: "#3182bd",
            },
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: [params.stats.billable],
          },
          {
            name: "Reporting",
            type: "bar",
            itemStyle: {
              color: "#9fcae1",
            },
            emphasis: {
              focus: "series",
            },
            data: [params.stats.reporting],
          },
          {
            name: "Total",
            type: "bar",
            itemStyle: {
              color: "#deebf7",
            },
            emphasis: {
              focus: "series",
            },
            data: [params.stats.total],
          },
        ],
      }}
    />
  );
};

import { Box, Divider, Tab, Tabs } from "@mui/material";
import { RegressionTest } from "../../api/developer";
import { useState } from "react";
import {
  RegressionTestDetailsResultsTab,
  useGetRegressionTestResults,
} from "./RegressionTestDetailsResultsTab";
import { RegressionTestDetailsJobsTab } from "./RegressionTestDetailsJobsTab";
import { RegressionTestDetailsValueExplorerTab } from "./RegressionTestDetailsValueExplorerTab";

type RegressionTestDetailsProps = {
  regressionTest: RegressionTest;
};

enum RegressionTestDetailsTabs {
  Results,
  Explore,
  Jobs,
}

export const RegressionTestDetails = ({
  regressionTest,
}: RegressionTestDetailsProps) => {
  const [tab, setTab] = useState(RegressionTestDetailsTabs.Results);
  const handleTabChange = (event, index: number) => {
    setTab(index);
  };

  const { data, isFetching } = useGetRegressionTestResults(regressionTest.id);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box sx={{ backgroundColor: "white" }}>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="Results" />
          <Tab label="Explore" />
          <Tab label="Jobs" />
        </Tabs>
      </Box>
      <Divider />
      {tab === RegressionTestDetailsTabs.Results && (
        <RegressionTestDetailsResultsTab
          results={data?.results ?? []}
          isFetching={isFetching}
        />
      )}
      {tab === RegressionTestDetailsTabs.Explore && (
        <RegressionTestDetailsValueExplorerTab results={data?.results ?? []} />
      )}
      {tab === RegressionTestDetailsTabs.Jobs && (
        <RegressionTestDetailsJobsTab regressionTest={regressionTest} />
      )}
    </Box>
  );
};

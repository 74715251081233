import { DefaultOptions, QueryClient, QueryClientProvider } from "react-query";
import { FC } from "react";
import { ReactQueryDevtools } from "react-query/devtools";

const config: {
  defaultOptions: DefaultOptions;
} = {
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 1000 * 60, // 1 minute cache
    },
  },
};

const queryClient = new QueryClient(config);

export const ReactQueryClientProvider: FC = ({ children }: any) => (
  // @ts-ignore
  <QueryClientProvider client={queryClient}>
    {children}
    <ReactQueryDevtools />
  </QueryClientProvider>
);

export enum BaseStatus {
  Active = 1,
  Inactive = 0,
  Deleted = 2,
}

export class BaseModel {
  public _id: string;
  public createdDate: Date;
  public modifiedDate: Date;
  public status: BaseStatus;

  constructor(props) {
    this._id = props._id;
    this.createdDate = new Date(props.createdAt);
    this.modifiedDate = new Date(props.modifiedAt);
    this.status = <BaseStatus>props.status;
  }
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedDeviceItemKeys: [],
};

export const suppliesState = createSlice({
  name: "supplies",
  initialState,
  reducers: {
    setSelectedDeviceItems: (state, action) => {
      state.selectedDeviceItemKeys = action.payload;
    },
  },
});

export const { setSelectedDeviceItems } = suppliesState.actions;
export const selectSupplies = (state: any) => state.supplies;
export default suppliesState.reducer;

import {
  parsePath,
  To,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useAppSelector } from "../store/store";
import { selectCurrentEntity } from "../store/entity";
import { useSetCurrentEntity } from "./entity";
import { useLogout } from "./auth";
import { getEntityUsers } from "../api/user";
import { useSnackbar } from "notistack";

/**
 * This hook should only be used by other internal hooks. It allows callers to
 * set the entity key inside the URL parameters.
 */
export const useEntityKeyParam = (): [string, (string, boolean) => void] => {
  const navigate = useNavigate();
  const location = useLocation();
  const { entityKey } = useParams();
  const [params] = useSearchParams();
  const changeParam = useCallback(
    (newEntityKey: string, removeParams: boolean = false) => {
      navigate({
        ...location,
        pathname: replaceEntityKeyFromPath(location.pathname, newEntityKey),
        search: removeParams ? "" : params.toString(),
      });
    },
    [location, navigate]
  );

  return [entityKey, changeParam];
};

export const useLocationWithEntity = () => {
  const location = useLocation();
  return (entityKey: string) => {
    return replaceEntityKeyFromPath(location.pathname, entityKey);
  };
};

/**
 * useWatchQueryStringEntity is a hook that returns no values and only monitors
 * changes to the currently selected entity and the entity key URL parameter. Any
 * changes to the param string will result in changing the currently selected
 * entity.
 *
 * This hook enables top-level pages to reload the currently selected entity
 * whenever the browser back button is pressed. The back button changes the URL
 * parameter and this hook updates the current entity in redux with the new
 * entity key.
 */
export const useWatchEntityKeyParam = () => {
  const { entityKey } = useParams();
  const currentEntity = useAppSelector(selectCurrentEntity);
  const setCurrentEntity = useSetCurrentEntity();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (entityKey != currentEntity?.entityKey) {
      getEntityUsers(entityKey)
        .then(() =>
          setCurrentEntity(entityKey, {
            updateParams: false,
          })
        )
        .catch((err) => {
          console.error(err);
          enqueueSnackbar(err.message, { variant: "error" });
        });
    }
  }, [currentEntity, entityKey]);
};

type EntityTo = To & {
  entityKey?: string;
};

/**
 * This custom hook is a wrapper around the React router builtin useNavigate
 * hook. This wrapper ensures that all calls to navigate(...) have the entity
 * path prepended to them.
 *
 * @example
 * // navigates to /entity/8158b687-25c9-4841-936f-cc063858cd8d/home
 * const navigate = useNavigateWithEntity();
 * navigate('/home')
 */
export const useNavigateWithEntity = () => {
  const navigate = useNavigate();
  const { onLogout } = useLogout();
  const currentEntity = useAppSelector(selectCurrentEntity);

  return useCallback(
    (to: EntityTo, { searchParams = {}, ...options }: any = {}) => {
      if (!currentEntity) onLogout().then();
      to = typeof to === "string" ? parsePath(to) : to;
      to.pathname = `/entity/${to.entityKey ?? currentEntity.entityKey}${
        to.pathname
      }`;
      return navigate({ ...searchParams, ...to }, options);
    },
    [currentEntity]
  );
};

/**
 * replaceEntityKeyFromPath returns the provided URL path but replaces the entity
 * key with a new entity key.
 * @param pathname
 * @param newEntityKey
 */
function replaceEntityKeyFromPath(
  pathname: string,
  newEntityKey: string
): string {
  const idStringLength = 24;
  const idParamLocation = 1;
  const splitPath: string[] = pathname.replace(/^\//, "").split("/");
  if (
    splitPath.length > 0 &&
    splitPath[idParamLocation] &&
    splitPath[idParamLocation].length === idStringLength
  ) {
    splitPath[idParamLocation] = newEntityKey;
    return `/${splitPath.join("/")}`;
  } else {
    return null;
  }
}

/**
 * getEntityKeyFromPath returns the entity key from a URL path. It assumes that
 * the URL path will look something like /entity/:entityKey/page.
 * @param pathname
 */
function getEntityKeyFromPath(pathname: string): string {
  const idStringLength = 36;
  const idParamLocation = 1;
  const splitPath: string[] = pathname.replace(/^\//, "").split("/");
  if (
    splitPath.length > 0 &&
    splitPath[idParamLocation] &&
    splitPath[idParamLocation].length === idStringLength
  ) {
    return splitPath[idParamLocation];
  } else {
    return null;
  }
}

import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import {
  createSetupIntent,
  getInvoiceDetails,
  getInvoices,
  getPaymentMethods,
  payInvoices,
  togglePaymentMethodAutoPay,
} from "../api/billing";
import { useSnackbar } from "notistack";
import { PaymentMethod } from "../api/types/billing";

export const useGetInvoices = (entityKey: string) => {
  return useQuery(
    ["entity", entityKey, "billing", "invoices"],
    () => {
      return getInvoices(entityKey);
    },
    {
      initialData: [],
    }
  );
};

export const useGetInvoiceDetails = (
  entityKey: string,
  referenceNbr: string
) => {
  return useQuery(
    ["entity", entityKey, "billing", "invoices", referenceNbr],
    () => getInvoiceDetails(entityKey, referenceNbr)
  );
};

export const useGetPaymentMethods = (
  entityKey: string
): UseQueryResult<Array<PaymentMethod>> => {
  return useQuery(
    ["entity", entityKey, "billing", "paymentMethods"],
    () => getPaymentMethods(entityKey),
    {
      initialData: [],
    }
  );
};

export const useToggleAutoPay = (entityKey: string) => {
  const client = useQueryClient();
  const snackbar = useSnackbar();
  return useMutation<
    unknown,
    unknown,
    { paymentMethodId: string; autoPayEnabled: boolean }
  >(
    ({ paymentMethodId, autoPayEnabled }) =>
      togglePaymentMethodAutoPay(entityKey, paymentMethodId, autoPayEnabled),
    {
      onSuccess: async (_, { autoPayEnabled }) => {
        await client.invalidateQueries([
          "entity",
          entityKey,
          "billing",
          "paymentMethods",
        ]);
        snackbar.enqueueSnackbar(
          `Successfully ${autoPayEnabled ? "enabled" : "disabled"} auto-pay`
        );
      },
      onError: (error) => {
        // @ts-ignore
        snackbar.enqueueSnackbar(error.message, { variant: "error" });
      },
    }
  );
};

export const useNewSetupIntent = (entityKey: string) => {
  return useQuery(
    ["entity", entityKey, "billing", "paymentMethod", "setupIntent"],
    () => createSetupIntent(entityKey)
  );
};

export const usePayInvoices = (entityKey: string) => {
  const client = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation<
    any,
    any,
    {
      paymentMethodId: string;
      invoiceReferenceNumbers: string[];
      amount: number;
    }
  >(
    ({ paymentMethodId, invoiceReferenceNumbers, amount }) =>
      payInvoices(entityKey, invoiceReferenceNumbers, paymentMethodId, amount),
    {
      onSuccess: async () => {
        await client.invalidateQueries([
          "entity",
          entityKey,
          "billing",
          "invoices",
        ]);
        enqueueSnackbar(`Successfully paid invoices`);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      },
    }
  );
};

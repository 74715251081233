import { fetchBase } from "./base";
import { User, UserWithRolesEntityAndPermissions } from "./types/user";

export const getUserDetails =
  (): Promise<UserWithRolesEntityAndPermissions> => {
    return fetchBase("/user/details", {
      method: "GET",
    }).then((res) => new UserWithRolesEntityAndPermissions(res));
  };

/**
 * Returns a list of users at an entity
 * @param entityKey
 */
export const getEntityUsers = (entityKey: string): Promise<User[]> => {
  return fetchBase(`/entity/${entityKey}/users`, {
    method: "GET",
  }).then((res: any[]) => res?.map((r) => new User(r)));
};

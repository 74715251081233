import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.scss";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { ReactQueryClientProvider } from "./state/react-query";
import { createTheme, ThemeProvider } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { green, grey, orange, red, yellow } from "@mui/material/colors";
import { LicenseInfo } from "@mui/x-license-pro";
import { datadogRum } from "@datadog/browser-rum";
import "./i18n";

LicenseInfo.setLicenseKey(
  "f95db15a34c9f9e085acab8949cb0ee6Tz01NTg5OSxFPTE3MDIyNDQ3MTMzNjUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

declare module "@mui/material/styles" {
  interface Theme {
    jobStatus: {
      sent: string;
      received: string;
      running: string;
      failed: string;
      completed: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    jobStatus?: {
      sent?: string;
      received?: string;
      running?: string;
      failed?: string;
      completed?: string;
    };
  }
}

const theme = createTheme({
  jobStatus: {
    sent: grey[300],
    received: yellow[800],
    running: orange[400],
    failed: red[400],
    completed: green[400],
  },
  palette: {
    mode: "light",
    success: {
      main: "#1aa35e",
    },
    secondary: {
      main: "#000000",
    },
  },
  typography: {
    fontFamily: "Roboto",
    h1: {
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "28px",
    },
    h2: {
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "28px",
    },
    h5: {
      fontSize: "15px",
      fontWeight: 500,
      lineHeight: "20px",
    },
    body2: {
      fontSize: "13px",
      fontWeight: 400,
      lineHeight: "20px",
    },
    caption: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "12px",
      color: "rgba(0, 0, 0, 0.66)",
    },
  },
});

export const stripePk = loadStripe(import.meta.env.VITE_STRIPE_PK);

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <SnackbarProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            {/*// @ts-ignore*/}
            <ReactQueryClientProvider>
              <App />
            </ReactQueryClientProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </SnackbarProvider>
  </ThemeProvider>
  // </React.StrictMode>
);

// datadogRum.init({
//   applicationId: "8776b2a9-558e-4b0a-9d37-8c6ecf22a593",
//   clientToken: "pub7587653ae2b3b9e009d6ad553ecfbe77",
//   site: "datadoghq.com",
//   service: "webadmin-v2",
//   env: import.meta.env.MODE === "prod" ? "prod" : "dev",
//   version: import.meta.env.VITE_BUILD_VERSION,
//   sampleRate: import.meta.env.BASE_URL.includes("localhost") ? 0 : 100,
//   sessionReplaySampleRate: 100,
//   trackInteractions: true,
//   trackResources: true,
//   trackLongTasks: true,
//   defaultPrivacyLevel: "allow",
// });
//
// datadogRum.startSessionReplayRecording();

import { Search as SearchIcon } from "@mui/icons-material";
import { GlobalSearchResults } from "./GlobalSearchResults";
import { searchEntities } from "../api/entity";
import { useEffect, useReducer, useRef } from "react";
import { alpha, InputBase, Modal, styled } from "@mui/material";
import { useDebounce } from "use-debounce";
import { useSnackbar } from "notistack";
import { useSetCurrentEntity } from "../hooks/entity";
import { EntityWithAncestors } from "../api/types/entity";

const initialState = {
  searchTerm: "",
  debouncedSearchTerm: "",
  searchPopupOpen: false,
  entities: [],
  entitiesLoading: false,
};

/**
 * This reducer will more easily allow us to manage the complex state that will
 * exist when we're showing search results for entities, users, skus, devices,
 * etc.
 * @param state
 * @param action
 */
const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_SEARCH_TERM":
      return { ...state, searchTerm: action.payload };
    case "UPDATE_DEBOUNCED_TERM":
      return {
        ...state,
        debouncedSearchTerm: action.payload,
        searchPopupOpen: !!action.payload,
        entitiesLoading: true,
      };
    case "SET_ENTITIES":
      return { ...state, entities: action.payload, entitiesLoading: false };
    case "RESET":
      return initialState;
  }
};

/**
 * Updates the reducer's search term property
 * @param {string} term - The term that we want to search for
 */
const updateSearchTerm = (term: string) => ({
  type: "UPDATE_SEARCH_TERM",
  payload: term,
});

/**
 * Updates the reducer's debounced search term
 * @param {string} term - The term that we want to search for
 */
const updateDebouncedSearchTerm = (term: string) => ({
  type: "UPDATE_DEBOUNCED_TERM",
  payload: term,
});

/**
 * Sets the entities that we found using the search term
 * @param entities
 */
const setEntities = (entities: EntityWithAncestors[]) => ({
  type: "SET_ENTITIES",
  payload: entities,
});

/**
 * Resets the global search state
 */
const reset = { type: "RESET" };

export const GlobalSearch = () => {
  const searchTermRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const stateDebouncedSearchTerm = state.debouncedSearchTerm;

  /**
   * Changes to the search term will be passed through this debouncer. Once they
   * come out the other side, we update the state using the reducer.
   */
  const [debouncedSearchTerm] = useDebounce(state.searchTerm, 350);
  useEffect(() => {
    dispatch(updateDebouncedSearchTerm(debouncedSearchTerm));
  }, [debouncedSearchTerm]);

  const { enqueueSnackbar } = useSnackbar();
  const setCurrentEntity = useSetCurrentEntity();

  const handleSearchTerm = (event) => {
    dispatch(updateSearchTerm(event.target.value));
  };

  const handleEntitySelect = async (entity: EntityWithAncestors) => {
    dispatch(reset);
    await setCurrentEntity(entity.entityKey);
  };

  useEffect(() => {
    (async () => {
      if (state.debouncedSearchTerm) {
        try {
          dispatch(setEntities(await searchEntities(debouncedSearchTerm)));
        } catch (e) {
          setEntities([]);
          enqueueSnackbar(e.message, { variant: "error" });
        }
      }
    })();
  }, [stateDebouncedSearchTerm]);

  const handleClose = () => {
    dispatch(reset);
  };

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        ref={searchTermRef}
        placeholder="Search…"
        inputProps={{ "aria-label": "search" }}
        onChange={handleSearchTerm}
        value={state.searchTerm}
      />
      {/*  // @ts-ignore*/}
      {state.searchPopupOpen && (
        <GlobalSearchResults
          filter={state.searchTerm}
          entities={state.entities}
          entitiesLoading={state.entitiesLoading}
          onClickEntity={handleEntitySelect}
          onClose={handleClose}
        />
      )}
    </Search>
  );
};

let Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

let SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

let StyledInputBase = styled(InputBase)(({ theme, onChange }) => ({
  color: "inherit",
  onChange: onChange,
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

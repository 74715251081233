import { Install } from "../../api/install";
import { Box, Divider, Tab, Tabs } from "@mui/material";
import { useQueryParams } from "../../hooks/params";
import { InstallDetailsSummaryTab } from "./InstallDetailsSummaryTab";

type InstallDetailsProps = {
  install: Install;
};

enum InstallDetailsTab {
  Summary,
  Settings,
  AuditLog,
  Devices,
  Jobs,
  NetworkTopology,
}

export const InstallDetailsRoot = ({ install }: InstallDetailsProps) => {
  const [tab, setTab] = useQueryParams("tab", InstallDetailsTab.Summary);
  const handleTabChange = (event, index: number) => {
    setTab(index);
  };
  return (
    <>
      <Box sx={{ backgroundColor: "white" }}>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="Summary" />
          <Tab label="Settings" disabled={true} />
          <Tab label="Devices" />
          <Tab label="Jobs" />
          <Tab label="Network Topology" />
          <Tab label="Audit Log" />
        </Tabs>
      </Box>
      <Divider />
      {tab === InstallDetailsTab.Summary && (
        <InstallDetailsSummaryTab install={install} />
      )}
    </>
    // <Grid container>
    //   <Grid item xs={6}>
    //     <Paper>
    //       <Box p={2}>Foobar</Box>
    //     </Paper>
    //   </Grid>
    // </Grid>
  );
};

import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { BackgroundColor } from "../../hooks/styles";
import { StyledDataGrid } from "../tables/StyledDataGrid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { gqlQuery } from "../../api/base";
import { gql } from "@apollo/client";
import { GridColumns } from "@mui/x-data-grid-pro";
import moment from "moment";

export const RegressionTestDetailsJobsTab = ({ regressionTest }) => {
  const { data, isFetching } = useGetRegressionTestJobs(regressionTest.id);
  const columns = useMemo(
    (): GridColumns => [
      {
        field: "status",
        headerName: "Status",
        renderCell: ({ row }) => <PollingJobStatusTag job={row} />,
        width: 150,
      },
      {
        field: "install",
        headerName: "Install",
        valueGetter: ({ row }) => row.install?.machineName,
        flex: 1,
      },
      {
        field: "createdDate",
        headerName: "Created",
        valueGetter: ({ row }) => row.createdDate,
        renderCell: ({ row }) => (
          <Typography variant="body2">
            {moment(row.createdDate).fromNow()}
          </Typography>
        ),
        flex: 1,
      },
      {
        field: "modifiedDate",
        headerName: "Last Updated",
        valueGetter: ({ row }) => row.modifiedDate,
        renderCell: ({ row }) => (
          <Typography variant="body2">
            {moment(row.modifiedDate).fromNow()}
          </Typography>
        ),
        flex: 1,
      },
      {
        field: "id",
        headerName: "ID",
        renderCell: ({ row }) => <IdQuickCopyButton id={row.id} />,
        width: 85,
      },
    ],
    []
  );

  const getPanelContent = useCallback(
    ({ row }) => (
      <>
        <JobLogsViewer jobKey={row.id} />
        <Divider />
      </>
    ),
    []
  );
  const getPanelHeight = useCallback(({}) => "auto", []);

  return (
    <>
      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <Box p={2} sx={{ backgroundColor: BackgroundColor.Gray }}>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Paper>
                <Box p={1} pl={2}>
                  <Typography variant="caption">Collection Status</Typography>
                  <Box pr={2} pt={1} pb={1}>
                    <RegressionTestJobStatusCategoricalDistribution
                      regressionTest={regressionTest}
                    />
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Divider />
          <StyledDataGrid
            autoHeight={false}
            disableSelectionOnClick={true}
            loading={isFetching}
            bordered={false}
            columns={columns}
            rows={data ?? []}
            getDetailPanelContent={getPanelContent}
            getDetailPanelHeight={getPanelHeight}
          />
        </Box>
      </Box>
    </>
  );
};

import { RegressionTestJobStatusCategoricalDistribution } from "./RegressionTestJobStatusCategoricalDistribution";
import { IdQuickCopyButton } from "../IdQuickCopyButton";
const JobLogsViewer = ({ jobKey }) => {
  const { data, isFetching } = useGetJobLogs(jobKey);

  const lines = useMemo(
    () => data?.logs.split("\n").filter((line) => line.length > 0),
    [data]
  );

  return (
    <Box sx={{ fontFamily: "monospace", maxHeight: 500, overflow: "scroll" }}>
      {(lines ?? []).map((v) => (
        <>
          <span>{v}</span>
          <div></div>
        </>
      ))}
    </Box>
  );
};

enum JobStatus {
  Sent = "sent",
  Received = "received",
  Running = "running",
  Failed = "failed",
  Completed = "completed",
}

type JobStatusTagProps = {
  status: JobStatus;
};

const JobStatusTag = ({ status }: JobStatusTagProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.jobStatus[status],
        padding: "1px 5px 1px 5px",
        borderRadius: "3px",
        height: "20px",
        color: status != JobStatus.Sent ? "white" : "black",
      }}
    >
      <Typography fontSize={13} fontWeight="bold">
        {status.toUpperCase()}
      </Typography>
    </Box>
  );
};

const PollingJobStatusTag = ({ job }) => {
  const [status, setStatus] = useState(job.status);
  const { data, refetch } = useGetJobStatus(job.id);

  // Using for triggering state updates that re-evaluate donePolling everytime we poll, since for
  // jobs that never start, data on it's own will never change.
  const [polled, setPolled] = useState(true);

  const donePolling = useMemo(() => {
    if (data == null) return false;
    const created = moment(data.createdDate);
    const before = moment().subtract(1, "hour");
    const donePolling =
      created.isBefore(before) ||
      data?.status === JobStatus.Failed ||
      data?.status === JobStatus.Completed;

    // If the job is still in the "sent" state a minute after it started, probably not worth polling.
    const probablyWontStart =
      created.isBefore(moment().subtract(1, "minute")) &&
      data?.status === JobStatus.Sent;
    return donePolling || probablyWontStart;
  }, [data, polled]);

  useEffect(() => {
    if (donePolling) return;
    const interval = setInterval(() => {
      setPolled((v) => !v);
      refetch().then();
    }, 2000);
    return () => clearInterval(interval);
  }, [donePolling]);

  useEffect(() => {
    if (data) setStatus(data.status);
  }, [data]);

  return <JobStatusTag status={status} />;
};

const useGetRegressionTestJobs = (regressionTestKey: string) => {
  return useQuery(["regressionTest", regressionTestKey, "jobs"], () =>
    gqlQuery(
      gql`
        query getRegressionTest($regressionTestKey: ObjectId!) {
          regressionTest(regressionTestKey: $regressionTestKey) {
            jobs {
              id
              status
              install {
                machineName
              }
              createdDate
              modifiedDate
            }
          }
        }
      `,
      {
        regressionTestKey,
      }
    ).then((res) => (res as any)?.regressionTest?.jobs)
  );
};

const useGetJobLogs = (jobKey: string) => {
  return useQuery(["job", jobKey, "logs"], () =>
    gqlQuery(
      gql`
        query getJobLogs($jobKey: ObjectId!) {
          job: getJob(jobKey: $jobKey) {
            logs
          }
        }
      `,
      {
        jobKey,
      }
    ).then((res) => (res as any)?.job ?? "")
  );
};

/**
 * Allows us to get the status separately so that we don't have to load the entire list of jobs
 * everytime we want to refresh only the status.
 * @param jobKey
 */
const useGetJobStatus = (jobKey: string) => {
  return useQuery(["job", jobKey, "status"], () =>
    gqlQuery<any>(
      gql`
        query getJobStatus($jobKey: ObjectId!) {
          job: getJob(jobKey: $jobKey) {
            status
            createdDate
          }
        }
      `,
      { jobKey }
    ).then((res) => res.job)
  );
};
